import React, { useState } from "react";
import styles from "./index.less";
import p1 from "@/assets/landing/partner/p1.png";
import p2 from "@/assets/landing/partner/p2.png";
import p3 from "@/assets/landing/partner/p3.png";
import p4 from "@/assets/landing/partner/p4.png";
import p5 from "@/assets/landing/partner/p5.png";
import p6 from "@/assets/landing/partner/p6.png";
import p7 from "@/assets/landing/partner/p7.png";
import p8 from "@/assets/landing/partner/p8.png";
import p9 from "@/assets/landing/partner/p9.png";
import p10 from "@/assets/landing/partner/p10.png";
import p11 from "@/assets/landing/partner/p11.png";
import bc1 from "@/assets/landing/v1/bc1@2x.png";
import bc2 from "@/assets/landing/v1/bc2@2x.png";
import bc3 from "@/assets/landing/v1/bc3@2x.png";
import b31 from "@/assets/landing/v1/b31@2x.png";
import Server from "./Server";
import { Avatars } from "../../../utils/imgs";

const Block3 = (prop) => {
  const topTitle1 = "Partners";
  const topTitle2 = "Don't just take our word for it.";
  const desc1 =
    "We partner with top financial institutions to offer professional, secure online credit services.";
  const desc2 =
    "Hear it straight from the people who trust us-our satisfied and happy clients!";
  const partner = [p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p1, p2];
  const features = [
    {
      img: bc1,
      title: "Download",
      desc: "Download and install the official APK",
    },
    {
      img: bc2,
      title: "Get Approved",
      desc: "Take 5 minutes to calculate your loan amount.",
    },
    {
      img: bc3,
      title: "Receive Cash",
      desc: "Enjoy your fun and remember to repay on time.",
    },
  ];
  const features2 = [
    {
      img: Avatars.AUSTRIA,
      title: "Noorlina Alal",
      sex: "男",
      desc: "Makapagpatak, may malusog na nilalaman, simpleng mga proseso ng pautang, araw-araw na sorpresa, magandang grafiks, flexible, at highly recommended. ",
    },
    {
      img: Avatars.BACAO,
      title: "Jardani Jovon",
      sex: "男",
      desc: "It's very easy to apply, and the form I had to fill out was straightforward. With very low interest rates and fast approval, I highly recommend this online lending app, 100%.",
    },
    {
      img: Avatars.ANUDON,
      title: "Tine Gasta",
      sex: "女",
      desc: "OKPeso offers great service and is simple to register. It suits anyone willing to apply. Looking forward to good news soon. You should definitely give it a shot!",
    },
    {
      img: Avatars.ENRIQUEZ,
      title: "Angelo Maniaol",
      sex: "女",
      desc: "It's really a good app. But it'll be great if the first payment will be scheduled on the applicant next salary date just for more convenient. Thank you",
    },
    {
      img: Avatars.GAMBOA,
      title: "Ana marie Calma",
      sex: "女",
      desc: "I highly recommend this lending app. It started with a small loan but increased my credit limit after repayment. I love the low interest rates and the freedom to accept or decline loan offers.",
    },
    {
      img: Avatars.AUSTRIA,
      title: "Noorlina Alal",
      sex: "男",
      desc: "Makapagpatak, may malusog na nilalaman, simpleng mga proseso ng pautang, araw-araw na sorpresa, magandang grafiks, flexible, at highly recommended. ",
    },
    {
      img: Avatars.BACAO,
      title: "Jardani Jovon",
      sex: "男",
      desc: "It's very easy to apply, and the form I had to fill out was straightforward. With very low interest rates and fast approval, I highly recommend this online lending app, 100%.",
    },
    {
      img: Avatars.ANUDON,
      title: "Tine Gasta",
      sex: "女",
      desc: "OKPeso offers great service and is simple to register. It suits anyone willing to apply. Looking forward to good news soon. You should definitely give it a shot!",
    },
    {
      img: Avatars.ENRIQUEZ,
      title: "Angelo Maniaol",
      sex: "女",
      desc: "It's really a good app. But it'll be great if the first payment will be scheduled on the applicant next salary date just for more convenient. Thank you",
    },
    {
      img: Avatars.GAMBOA,
      title: "Ana marie Calma",
      sex: "女",
      desc: "I highly recommend this lending app. It started with a small loan but increased my credit limit after repayment. I love the low interest rates and the freedom to accept or decline loan offers.",
    },
    {
      img: Avatars.AUSTRIA,
      title: "Noorlina Alal",
      sex: "男",
      desc: "Makapagpatak, may malusog na nilalaman, simpleng mga proseso ng pautang, araw-araw na sorpresa, magandang grafiks, flexible, at highly recommended. ",
    },
    {
      img: Avatars.BACAO,
      title: "Jardani Jovon",
      sex: "男",
      desc: "It's very easy to apply, and the form I had to fill out was straightforward. With very low interest rates and fast approval, I highly recommend this online lending app, 100%.",
    },
    {
      img: Avatars.ANUDON,
      title: "Tine Gasta",
      sex: "女",
      desc: "OKPeso offers great service and is simple to register. It suits anyone willing to apply. Looking forward to good news soon. You should definitely give it a shot!",
    },
    {
      img: Avatars.ENRIQUEZ,
      title: "Angelo Maniaol",
      sex: "女",
      desc: "It's really a good app. But it'll be great if the first payment will be scheduled on the applicant next salary date just for more convenient. Thank you",
    },
    {
      img: Avatars.GAMBOA,
      title: "Ana marie Calma",
      sex: "女",
      desc: "I highly recommend this lending app. It started with a small loan but increased my credit limit after repayment. I love the low interest rates and the freedom to accept or decline loan offers.",
    },
    {
      img: Avatars.AUSTRIA,
      title: "Noorlina Alal",
      sex: "男",
      desc: "Makapagpatak, may malusog na nilalaman, simpleng mga proseso ng pautang, araw-araw na sorpresa, magandang grafiks, flexible, at highly recommended. ",
    },
    {
      img: Avatars.BACAO,
      title: "Jardani Jovon",
      sex: "男",
      desc: "It's very easy to apply, and the form I had to fill out was straightforward. With very low interest rates and fast approval, I highly recommend this online lending app, 100%.",
    },
    {
      img: Avatars.ANUDON,
      title: "Tine Gasta",
      sex: "女",
      desc: "OKPeso offers great service and is simple to register. It suits anyone willing to apply. Looking forward to good news soon. You should definitely give it a shot!",
    },
    {
      img: Avatars.ENRIQUEZ,
      title: "Angelo Maniaol",
      sex: "女",
      desc: "It's really a good app. But it'll be great if the first payment will be scheduled on the applicant next salary date just for more convenient. Thank you",
    },
    {
      img: Avatars.GAMBOA,
      title: "Ana marie Calma",
      sex: "女",
      desc: "I highly recommend this lending app. It started with a small loan but increased my credit limit after repayment. I love the low interest rates and the freedom to accept or decline loan offers.",
    },
    {
      img: Avatars.AUSTRIA,
      title: "Noorlina Alal",
      sex: "男",
      desc: "Makapagpatak, may malusog na nilalaman, simpleng mga proseso ng pautang, araw-araw na sorpresa, magandang grafiks, flexible, at highly recommended. ",
    },
    {
      img: Avatars.BACAO,
      title: "Jardani Jovon",
      sex: "男",
      desc: "It's very easy to apply, and the form I had to fill out was straightforward. With very low interest rates and fast approval, I highly recommend this online lending app, 100%.",
    },
    // {
    //   img: Avatars.GERONA,
    //   title: "Jenea Malana",
    //   sex: "女",
    //   desc: "This app is the best. Very fast and easy transactions. Their term is the longest among all online loaning apps. I applied for the first time and i paid it after a month. Their interest is the lowest. You can get the amount that you applied for. This is my 2nd time and it's hassle free. I will recommend this app to my friends.",
    // },
    // {
    //   img: Avatars.GODITO,
    //   title: "Vancis",
    //   sex: "男",
    //   desc: "OKPeso provides excellent service and is easy to register. I believe it's suitable for everyone who is willing to apply. I hope to receive good news soon, and you should definitely give it a try!",
    // },
    // {
    //   img: Avatars.NAVARRO,
    //   title: "Honne",
    //   sex: "女",
    //   desc: "It's very easy to apply, and the form I had to fill out was straightforward. With very low interest rates and fast approval, I highly recommend this online lending app, 100%.",
    // },
    // {
    //   img: Avatars.JOHNPHILIP,
    //   title: "Satan",
    //   sex: "男",
    //   desc: "I loaned 500 at first then the second one was 3000 because I paid early. It's the best loan app with reasonable interest according to your choice of terms. Unlike all other apps that harrass you with calls, and with only 7 days to pay, OKPeso is the best since you can choose between 1-6 terms where the interval for payments is 15 days. Trust me, I tried almost every loan app and this is the best one so far.  Just commendable, right!",
    // },
    // {
    //   img: Avatars.QUEZON,
    //   title: "Rica",
    //   sex: "女",
    //   desc: "It is very easy to operate, rich in content, the materials inside are novel, the loan procedures are very simple, there are surprises and benefits every day, there are many rewards, and the graphics are beautiful, it is great! This is very loan software, flexible and convenient to use! Worth recommending to everyone! great!",
    // },
  ];
  const { isMobile = true, handleOpenService } = prop;
  return (
    <div className={["block3", !isMobile && "block3Pc"].join(" ")}>
      <div className={"topTitle"}>
        <div className={"sign"} />
        {topTitle1}
      </div>
      <div className={"desc3"}> {desc1}</div>
      <div className={"content31"}>
        <div className={"itemAll"}>
          {partner.map((item) => (
            <img className={"itemOne"} src={item} />
          ))}
        </div>
      </div>
      <div className={"content32"}>
        {features.map((item) => (
          <div className={"itemOne"}>
            <img src={item.img} />
            <div className={"info"}>
              <div>{item.title}</div>
              <div className={"infoDesc"}>{item.desc}</div>
            </div>
          </div>
        ))}
      </div>
      <div className={["topTitle", "topTitle2"].join(" ")}>
        <div className={"sign"} />
        {topTitle2}
      </div>
      <div className={"desc3"}> {desc2}</div>

      <div className={"content33"}>
        <div className={"item3All"}>
          {features2.map((item) => (
            <div className={"item3One"}>
              <div className={"it3One"}>
                <div className={"itemTop"}>
                  <img src={item.img} />
                  <div className={"topInfo"}>
                    <div>{item.title}</div>
                    <div className={"imgs"}>
                      {[1, 1, 1, 1, 1].map(() => (
                        <img src={b31} />
                      ))}
                    </div>
                  </div>
                </div>

                <div className={"infoDesc"}>{item.desc}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Server isMobile={isMobile} handleOpenService={handleOpenService} />
    </div>
  );
};
export default Block3;
