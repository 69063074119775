import React, { useState, useEffect } from "react";
import "./index.less";
import { Button, Slider, Input, Icon, message, Form, Checkbox } from "antd";
import a1 from "@/assets/imgs/home/borrow/@AUSTRIA.png";
import a2 from "@/assets/imgs/home/borrow/@GAMBOA.png";
import a3 from "@/assets/imgs/home/borrow/@QUEZON.png";
import a4 from "@/assets/imgs/home/borrow/@ANUDON.png";
import a5 from "@/assets/imgs/home/borrow/@JOHNPHILIP.png";
import userIcon from "@/assets/imgs/home/组 4447@2x.png";
import { sleep } from "@/utils/common";
import PrivacyPolicyModal from "@/components/PrivacyPolicyModal";
import OppoH5Modal from "@/Page/oppoH5/OppoH5Modal";
import { DataArea } from "@/Home/DataArea";
import { Recognized } from "@/Home/Recognized";
import { Features } from "@/Home/Features";
import { Partners } from "@/Home/Partners";

import { reportPage, getUuidByLocalStorage } from "@/utils/tools";
import axios from "axios";
import { reportSys } from "../../utils/tools";

const OppoH5 = () => {
  const realTimeArr = [
    {
      img: a1,
      content:
        "AUS*** applied for <span class='real-time-amont-red'>5,400</span> three minutes ago.",
    },
    {
      img: a2,
      content:
        "GAM*** applied for <span class='real-time-amont-red'>12,000</span> four minutes ago.",
    },
    {
      img: a3,
      content:
        "QUE*** applied for <span class='real-time-amont-red'>9,800</span> one minute ago.",
    },
    {
      img: a4,
      content:
        "ANU*** applied for <span class='real-time-amont-red'>10,000</span> five minutes ago.",
    },
    {
      img: a5,
      content:
        "JOH*** applied for <span class='real-time-amont-red'>6,300</span> four minutes ago.",
    },
    // { img: a6, content: "LC*** applied for PHP 2000 one minute ago." },
  ];
  const [realTimeItem, setRealTimeItem] = useState(realTimeArr[0]);
  const [realTimeCount, setRealTimeCount] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [smsCode, setSmsCode] = useState("");
  const [policyModalVisible, setPolicyModalVisible] = useState(false);
  const [uuid, setUuid] = useState("");
  const [sliderValue, setSliderValue] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [source, setSource] = useState("oppoH5");
  const [isSending, setIsSending] = useState(false); 
  const [countdown, setCountdown] = useState(0); 

  const defaultValue = 20000;
  const animationDuration = 3000;
  useEffect(() => {
    // console.log('countdown',countdown);
    // console.log('isSending',isSending);
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0 && isSending) {
      resetCountdown();
    }
  }, [countdown, isSending]);
  useEffect(() => {
    reportPage();
    setUuid(getUuidByLocalStorage());
    let startTime = performance.now();
    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / animationDuration, 1);
      const newValue = Math.round(defaultValue * progress);
      setSliderValue(newValue);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };
    requestAnimationFrame(animate);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const target = document.querySelector("#realTime-selections");
      realTimeAnimate(target);
      sleep(1000).then(() => {
        if (realTimeCount + 1 >= realTimeArr.length) {
          setRealTimeItem(realTimeArr[0]);
          setRealTimeCount(0);
        } else {
          setRealTimeItem(realTimeArr[realTimeCount + 1]);
          setRealTimeCount(realTimeCount + 1);
        }
      });
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, [realTimeCount]);

  const realTimeAnimate = (element) => {
    if (!element) return;
    element.animate(
      [
        { opacity: 1, transform: "translateY(0)" },
        { opacity: 0.75, transform: "translateY(-5px)" },
        { opacity: 0, transform: "translateY(-10px)" },
      ],
      {
        duration: 1000,
        easing: "linear",
      }
    );
  };

  const onSliderChange = (value) => {
    // setAmount(value);
    setSliderValue(value);
  };

  const sendSms = async () => {
    if( isSending){
      return;
    }
    if (!phoneNumber) {
      message.error("Please enter your phone number");
      return;
    }
    try {
      // const response = await axios.post("/api/user/login/sms/third", {
      const response = await axios.post("/oppoh5/api/user/login/sms/third", {
        phone: "+63" + phoneNumber,
        uid: uuid,
        source: source,
      });
      // console.log(JSON.stringify(response));
      if (response.status === 200 ) {
        if(response.data.ret === 0){
          message.success("SMS code sent successfully");
          reportSys("sendSms");
          setCountdown(60);
          setIsSending(true);
          // 已经注册过按登录流程走
        }else if(response.data.ret === 1206 || response.data.ret === 1205){
          message.success("You already have a login record and have been automatically logged in.");
          reportSys("loginSuccess");
          setModalVisible(true);
        }
      } else {
        // console.log("Error Response:", JSON.stringify(response));
        message.error(response.data.msg || "Unexpected error occurred");
      }
    } catch (error) {
      message.error("System Error");
    }
  };

  const handleChange = (e) => {
    const input = e.target.value;
    if (input === "" || /^9\d{0,9}$/.test(input)) {
      setPhoneNumber(input);
    }
  };

  const smsOnchange = (e) => {
    const input = e.target.value;
    if (/^\d*$/.test(input)) {
      setSmsCode(input);
    }
  };

  const login = async () => {
    // console.log("phoneNumber", phoneNumber);
    // console.log("smsCode", smsCode);
    // console.log("checkBox", isChecked);
    if (!phoneNumber || !smsCode) {
      message.error("Please enter both phone number and SMS code");
      return;
    }
    if (isChecked == false) {
      message.warning("Please Select I agree to the《Privacy Policy》");
      return;
    }
    try {
      // const response = await axios.post("/api/user/login/phone/third", {
      const response = await axios.post("/oppoh5/api/user/login/phone/third", {
        phone: "+63" + phoneNumber,
        code: smsCode,
        uid: uuid,
        source: source,
      });
      if (response.status === 200) {
        // 1206  h5登录 // 1205  app登录过
        if( response.data.ret === 0 || response.data.ret === 1206 || response.data.ret === 1205){
          message.success("Login successful");
          reportSys("loginSuccess");
          setModalVisible(true);
        }
      } else {
        message.error(response.data.msg);
      }
    } catch (error) {
      message.error("Login failed. Please check your credentials");
    }
  };

  const showModal = () => {
    setPolicyModalVisible(true);
  };
  const handleCancel = () => {
    setPolicyModalVisible(false);
  };

  const cancelModal = () => {
    setModalVisible(false);
  };

  const resetCountdown = () => {
    setIsSending(false);
    setCountdown(0);
  };

  return (
    <div>
      <div className="oppoH5-container">
        <div className="main">
          <div className="pannel">
            <div className="real-time" id="realTime-selections">
              <img src={realTimeItem.img} alt="realTimeImg" />
              {/* <span>{realTimeItem.content}</span> */}
              <span
                dangerouslySetInnerHTML={{ __html: realTimeItem.content }}
              />
            </div>
            <div className="apply-now">
              <div className="customer">
                <img src={userIcon} alt="userIcon" />
                <div className="customer-text-container">
                  <p className="title">1 ID, sapat na para mag-apply</p>
                  <p className="desc">Fast / Low fees / Long term</p>
                </div>
              </div>
              <div className="borrow-amount">
                <p className="max-amount">MAX Credit Amount PHP 30,000</p>
                {/* <div> */}
                <div className="main-amount">
                  <span className="php">PHP</span>
                  <span className="amount">{sliderValue.toLocaleString()}</span>
                  {/* <AnimatedNumber start={0} end={6500} duration={2000} className={"amount"}/> */}
                </div>
                <Slider
                  value={sliderValue}
                  min={500}
                  max={30000}
                  onChange={onSliderChange}
                  className="main-slider"
                />
                <div className="amount-rate-desc">
                  <p>Daily interest as low as 0.2%.</p>
                  <p>Up to 180 days</p>
                </div>
                <Form>
                  <Form.Item style={{ margin: "2vw 0vw" }}>
                    <Input
                      // id="phoneNumberInput"
                      className="inputPhoneNumber"
                      addonBefore={
                        <Icon
                          type="mobile"
                          style={{ color: "rgba(118, 87, 215)" }}
                        />
                      }
                      value={phoneNumber}
                      onChange={handleChange}
                      prefix={"+63"}
                      placeholder="Phone number"
                      type="tel"
                      maxLength={10}
                    />
                  </Form.Item>
                  <Form.Item style={{ margin: "2vw 0vw" }}>
                    <Input
                      placeholder="SMS Code"
                      className="smsInput"
                      value={smsCode}
                      onChange={smsOnchange}
                      type="tel"
                      maxLength={5}
                      // prefix={<Icon
                      //   type="safety"
                      //   style={{ color: "rgba(118, 87, 215)" }}
                      // />}
                      // suffix={
                      //   <span
                      //     style={{ color: "rgba(118, 87, 215)" }}
                      //     onClick={sendSms}
                      //   >
                      addonBefore={
                        <Icon
                          type="safety"
                          style={{ color: "rgba(118, 87, 215)" }}
                        />
                      }
                      suffix={
                        <span
                          // style={{ color: "rgba(118, 87, 215)", fontSize:'4vw'}}
                          onClick={sendSms}
                        >
                          {isSending ? `${countdown}s` : "Send"}
                        </span>
                      }
                    />
                  </Form.Item>
                  <Form.Item style={{ margin: "1vw 0vw" }}>
                    <Checkbox
                      checked={isChecked}
                      onChange={(e) => {
                        // console.log(`checked = ${e.target.checked}`);
                        setIsChecked(e.target.checked);
                      }}
                    >
                      I agree to the
                      <a onClick={showModal}>《Privacy Policy》</a>
                    </Checkbox>
                  </Form.Item>
                </Form>
                <Button className="apply-now-button" onClick={login}>
                  LOGIN
                </Button>
                {/* <p className="apply-desc">Apply in 3 minutes!</p> */}
              </div>
              {/* Modal for Privacy Policy */}
              {policyModalVisible && (
                <PrivacyPolicyModal
                  showModal={policyModalVisible}
                  handleCancel={handleCancel}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <DataArea isMobile={true} />
      <Recognized isMobile={true} />
      <Features isMobile={true} />
      <Partners isMobile={true} />
      {modalVisible && (
        <OppoH5Modal visible={modalVisible} cancelModal={cancelModal} />
      )}
    </div>
  );
};

export default OppoH5;
