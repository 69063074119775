const addrTime = [
  { value: 1, label: "0-6 Months" },
  { value: 2, label: "7-12 Months" },
  { value: 3, label: "1-3 Years" },
  { value: 4, label: "3-5 Years" },
  { value: 5, label: "5-7 Years" },
  { value: 6, label: "7 Years +" },
];
const religion = [
  { value: -1, label: "NO" },
  { value: 1, label: "Christianity" },
  { value: 2, label: "Islam" },
  { value: 3, label: "Catholicism" },
  { value: 4, label: "Hinduism" },
  { value: 5, label: "Buddhism" },
  { value: 6, label: "Confucius" },
];

const martialStatus = [
  { value: 1, label: "Single" },
  { value: 2, label: "In Relationship" },
  { value: 3, label: "Married" },
  { value: 4, label: "Divorce" },
  { value: 5, label: "Widower/ Widow" },
];
const housingStatus = [
  { value: 1, label: "Owned Property" },
  { value: 2, label: "Live With Parents" },
  { value: 3, label: "Rent A House" },
  { value: 5, label: "Company Provides" },
  { value: 4, label: "Other" },
];
const education = [
  { value: 1, label: "Vocational" },
  { value: 2, label: "Graduate" },
  { value: 3, label: "High School" },
  { value: 4, label: "Undergraduate" },
  { value: 5, label: "Bachelor" },
  { value: 6, label: "Master" },
  { value: 7, label: "Doctor" },
  { value: 8, label: "Other" },
];
const monthlyIncome = [
  { value: 1, label: "0-5K" },
  { value: 2, label: "5K-10K" },
  { value: 3, label: "10K-15K" },
  { value: 4, label: "15K-20K" },
  { value: 5, label: "20K-25K" },
  { value: 6, label: "25K-30K" },
  { value: 7, label: "30K-35K" },
  { value: 8, label: "35K+" },
];

export const dict = {
  addrTime,
  religion,
  martialStatus,
  housingStatus,
  education,
  monthlyIncome,
};
