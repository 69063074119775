import React from "react";
import {
  getDeviceType,
  reportDownload,
  reportDownloadApk,
  reportGoAppStore,
} from "@/utils/tools";
import "./oppoH5Modal.less";


class OppoH5Modal extends React.PureComponent {
  state = {
    visible: false,
    appMarketUrl:"oaps://mk/dt?pkg=tech.codeblock.okpeso&goback=1",
  };

  componentDidMount() {
    const {visible} = this.props;
    this.setState({visible: visible})
  }

  componentWillUnmount() {
    this.setState({visible: false})
    const {cancelModal} =this.props
    cancelModal();
  }

  // redirectToAppMarket() {
  //   let deviceType = getDeviceType();
  //   const { downLoanApkUrl } = this.props;
  //   let appMarketUrl = "oaps://mk/dt?pkg=tech.codeblock.okpeso&goback=1";
  //   if (deviceType) {
  //     //弹提示框 去应用商城
  //     reportGoAppStore();
  //     this.setState({ downloadModalVisible: true });
  //     window.location.href = appMarketUrl;
  //   } else {
  //     reportDownload();
  //     //直接下载apk
  //     window.location.href = downLoanApkUrl;
  //   }
  // }

  gotoAppStore = () => {
    reportGoAppStore();
    window.location.href = this.state.appMarketUrl;
    this.setState({visible: false})
    const {cancelModal} =this.props
    cancelModal();
  };

  render() {
    const { visible } = this.state;
    
    return visible ? ( //走应用商城
      <div className={"userAgentModal"}>
        <div className={"userAgentMask"} />
        <div className={"content"}>
          <div className={"infoModal"}>
            <div className={"gotoDesc"}>
            Congratulations on your approved credit limit! Secure it with just one valid ID – it's that simple and quick!
            </div>
            <div
              className={"gotoButton"}
              onClick={() => this.gotoAppStore()}
            >
              GET IT NOW
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}
export default OppoH5Modal;
