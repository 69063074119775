import React from "react";
import DocumentTitle from "react-document-title";
import { enquireScreen } from "enquire-js";
import Header from "./Header";
// import Banner from "./Banner";
import Footer from "./Footer";
import  "./static/style";
import { reportPage } from "../utils/tools";
import { DataArea } from "./DataArea";
import { Recognized } from "./Recognized";
import { Features } from "./Features";
import { Partners } from "./Partners";
import { Borrow } from "./Borrow";
import { BorrowProcess } from "./BorrowProcess";
import { homeJumpToSection } from "../utils/common";





let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class Home extends React.PureComponent {
  state = {
    isMobile,
    showShadow: false,
    isBgWhite: false,
    activeHome: false,
    activeProduct: false,
    activefaq: false,
    isScroll: false,
  };


  componentDidMount() {
    reportPage();
    
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    window.addEventListener("scroll", this.bindScroll);
  }

  bindScroll = (event) => {
    this.setState({
      isScroll: true,
    });
    const scrollTop =
      (event.srcElement ? event.srcElement.documentElement.scrollTop : false) ||
      window.pageYOffset ||
      (event.srcElement ? event.srcElement.body.scrollTop : 0);
    if (scrollTop > 0) {
      this.setState({
        isBgWhite: true,
      });
    } else {
      this.setState({
        isBgWhite: false,
      });
    }

    // const homeOT = document.getElementById('home').offsetTop - 64;
    // const producteOT =
    //   document.getElementById("product").offsetTop - window.innerHeight / 2;
    // const faqOT =
    //   document.getElementById("FAQ").offsetTop - window.innerHeight / 2;
    // if (scrollTop < producteOT) {
    //   this.setState({
    //     activeHome: false,
    //     activeProduct: false,
    //     activefaq: false,
    //   });
    // } 
  };
  navToShadow = (e) => {
    this.setState({ showShadow: e.mode === "leave" });
  };

  render() {
    return [
      <Header
        key="header"
        isScroll={this.state.isScroll}
        activeHome={this.state.activeHome}
        activeProduct={this.state.activeProduct}
        activefaq={this.state.activefaq}
        handleclick={homeJumpToSection}
        className={(this.state.isBgWhite || this.state.isMobile) ? "btn-white" : ""}
        isMobile={this.state.isMobile}
      />,
      // <Banner
      //   key="banner"
      //   isMobile={this.state.isMobile}
      //   navToShadow={this.navToShadow}
      // />,
      <Borrow isMobile={this.state.isMobile}/>,
      <DataArea isMobile={this.state.isMobile}/>,
      <Recognized isMobile={this.state.isMobile}/>,
      // <Feature key="feature" isMobile={this.state.isMobile} />,
      <Features isMobile={this.state.isMobile}/>,
      <BorrowProcess isMobile={this.state.isMobile}/>,
      <Partners isMobile={this.state.isMobile}/>,
      // <FAQ key="FAQ" isMobile={this.state.isMobile} />,
      <Footer key="footer" isMobile={this.state.isMobile} />,
      <DocumentTitle title="OKPeso" />,
    ];
  }
}
export default Home;
