import React, { PureComponent } from "react";

import testAmountLogo from "../assets/imgs/testAmountLogo.png";
import testAmountContentImg from "../assets/imgs/testAmountContentImg.png";
import testAmountTitle from "../assets/imgs/testAmountTitle.png";
import testAmountGif from "../assets/imgs/testAmountGif.gif";
import honor from "../assets/imgs/honor.png";
import company from "../assets/imgs/company.png";
import testAmountLoading from "../assets/imgs/testAmountLoading.gif";
import testAmountSuccess from "../assets/imgs/testAmountSuccess.png";

import UserAgentConponent from "../components/UserAgentConponent";

import "./testAmount.less";
import { message } from "antd";

import sha256 from "crypto-js/sha256";
import { canGoMarket, reportDownload, reportPage } from "../utils/tools";

export class TestAmount extends PureComponent {
  state = {
    repayRef: React.createRef(),
    stWayKey: "",
    stChannelKey: "",
    phoneFocus: false,
    count: 3,
    hasCount: false,
    downLoanUrl: null,
    phone: null,
    code: null,
    hasSend: false,
    canSend: false,
    isErrorMsg: false,
    testModal: false,
    phoneInfo: {},
    loadingModal: false,
    userAgentVisible: false,
    successModal: false,
  };

  props = this.props;
  componentDidMount() {
    console.log(window, this.props);
    const { fbq } = window;
    if (typeof fbq === "function") {
      fbq("trackCustom", "TestPage", { eventTag: "adtiger" });
    }

    reportPage();

    fetch(window.location.origin + `/api/limit/test/page?a=1&k=10`, {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=UTF-8" },
      mode: "no-cors",
      cache: "default",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.ret === 0) {
          this.setState({
            downLoanUrl: res.data.downloadUrl,
          });
        }
      });
  }

  checkPhone(phoneCheck) {
    // 去除空格, 横杠, 括号
    if (!phoneCheck) {
      return "";
    }
    let phone = String(phoneCheck)
      .replace(/ /g, "")
      .replace(/-/g, "")
      .replace(/\(/g, "")
      .replace(/\)/g, "");
    if (phone.length < 3) {
      return phone;
    }
    let areaCode = "+63";
    let preAreaCode = areaCode.replace("+", "");
    // 判断手机号是否以+号开始
    if (phone.indexOf("+") === 0) {
      return phone;
    }
    // 判断手机号是否少了+
    if (phone.indexOf(preAreaCode) === 0) {
      return "+" + phone;
    }
    // 判断手机号是否以00开始
    if (phone.indexOf("00" + preAreaCode) === 0) {
      return "+" + phone.substring(2);
    } // 判断手机号是否以0开始
    if (phone.indexOf("0") === 0) {
      return areaCode + phone.substring(1);
    }
    return areaCode + phone;
  }

  checkPhoneForFB(phoneCheck) {
    let phone = this.checkPhone(phoneCheck);
    return phone.replace("+", "");
  }

  handleSendCode = () => {
    const { phone, isErrorMsg, downLoanUrl } = this.state;
    if (!phone || phone === "" || isErrorMsg) {
      let tMsg = "";
      tMsg = "10 digit Philippine number only";
      message.error(tMsg, 2);
      return;
    }

    this.setState({ loadingModal: true });
    fetch(window.location.origin + `/api/limit/test/sms`, {
      method: "POST",
      headers: { "Content-Type": "application/json;charset=UTF-8" },
      // mode:'no-cors',
      cache: "default",
      body: JSON.stringify({
        appId: 1,
        phone,
        sendSms: 0,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ loadingModal: false });
        if (res && res.ret === 0) {
          const { reqIp, reqPhone } = res.data;
          let fbPhone = sha256(this.checkPhoneForFB(reqPhone)).toString();
          localStorage.setItem("reqIp", reqIp);
          let reqParm = {
            external_id: fbPhone, //外部id
            ph: fbPhone, //手机号
            client_ip_address: reqIp,
            client_user_agent: navigator.userAgent,
          };
          const { fbq } = window;
          fbq("trackCustom", "TestDownload", reqParm);

          this.setState({ phoneFocus: false, testModal: true, count: 3 });
          setTimeout(() => {
            // Toast.hide()
            let count = 3;
            let nSInv = setInterval(() => {
              count--;
              this.setState({ count });
              if (count === 0) {
                clearInterval(nSInv);
                setTimeout(() => {
                  let deviceType = canGoMarket();
                  if (deviceType) {
                    this.setState({ userAgentVisible: true, testModal: false });
                  } else {
                    reportDownload();
                    //旧逻辑
                    this.setState({ successModal: true, testModal: false });
                    window.location.href = downLoanUrl;
                  }
                }, 1000);
              }
            }, 1000);
          }, 500);
        }
      });
  };

  handleValue = (e, type) => {
    const {
      target: { value },
    } = e;
    switch (type) {
      case "phone":
        const phonePattern = /^(9[0-9])\d{8}$/;
        this.setState({ phone: value, isErrorMsg: !phonePattern.test(value) });
        break;
      default:
        break;
    }
  };
  render() {
    const {
      isErrorMsg,
      testModal,
      count,
      loadingModal,
      userAgentVisible,
      successModal,
    } = this.state;

    const { phoneFocus, downLoanUrl } = this.state;
    let UserAgentConponentProps = {
      downLoanApkUrl: downLoanUrl,
    };
    return (
      <div
        className={["amountTest", phoneFocus ? "amountTestFocus" : null].join(
          " "
        )}
      >
        <div className={"navs"}>
          <img className={"logo"} src={testAmountLogo} alt="testAmountLogo" />
        </div>
        <div className={["info"].join(" ")}>
          <img
            className={"testAmountContentImg"}
            src={testAmountContentImg}
            alt="testAmountContentImg"
          />
          <div className={"infoContent"}>
            <img
              className={"testAmountTitle"}
              src={testAmountTitle}
              alt="testAmountLogo"
            />
            <div className={"desc"}>
              {"OKPeso provides fast and easy online 24/7 loans up to ₱20,000"}
            </div>
            <div className={"amount"}>{"PRE-APPROVAL LOAN AMOUNT TEST"}</div>
            <img className={"iGif"} src={testAmountGif} alt="testAmountGif" />
            <div className={"loanInfo"}>{"*Age:18+ years old"}</div>
            <div className={"loanInfo"}>{"*Loan Period: 91Days+"}</div>
            <div className={"loanInfo"}>{"*Interest rate:1% per month"}</div>
            <div className={"phone"}>
              <span className={"pre"}>{"+63"}</span>
              <input
                className={"pInput"}
                minLength={10}
                maxLength={10}
                onChange={(e) => this.handleValue(e, "phone")}
                onFocus={() => this.setState({ phoneFocus: true })}
                placeholder={"input your phone number"}
              />
            </div>
            {isErrorMsg && (
              <div className={"errorMsg"}>
                {"10 digit Philippine number only"}{" "}
              </div>
            )}
            <div className={"submit"} onClick={() => this.handleSendCode()}>
              {"Test Now"}
            </div>
          </div>
          {/* sec */}
          <div className={"sec"}>
            <div className={"sectitle"}>
              <img className={"iconImg"} src={honor} alt="honor" />
              <div>{"SEC INFORMATION"}</div>
            </div>
            <div className={"company"}>
              <img className={"iconImg"} src={company} alt="company" />
              {"Codeblock Lending Inc."}
            </div>
            <div className={"tip"}>{"COMPANY REGISTRATION"}</div>
            <div className={"tipNo"}>{"NO.CS201913681"}</div>
            <div className={"tip"}>{"CERTIFICATE OF AUTHORITY"}</div>
            <div className={"tipNo"}>{"NO.3059"}</div>
          </div>
          <div className={"end"}>
            {/* {'4th Floor, Aseana Three Bldg., Macapagal Ave. cor Aseana Ave.,Aseana City, Tambo, CITY OF PARAnAQUE, NCR, FOURTH DISTRICT, Philippines'} */}
          </div>
          <div className={"us"}>{"Official Website: www.okpeso.com"}</div>
        </div>

        {testModal && (
          <div className={"testModal"}>
            <div className={"testMask"} />

            <div className={"content"}>
              <div className={"infoModal"}>
                <img
                  className={"loading"}
                  src={testAmountLoading}
                  alt="testAmountLoading"
                />
                <div className={"desc"}>{`${count}s`}</div>
              </div>
            </div>
          </div>
        )}

        {successModal && (
          <div className={"testModal"}>
            <div className={"testMask"} />

            <div className={"content"}>
              <div className={"infoModal"}>
                <img
                  className={"testAmountSuccess"}
                  src={testAmountSuccess}
                  alt="testAmountSuccess"
                />
                <div className={"title"}>{"Congratulations!"}</div>
                <div className={"desc"}>
                  {
                    "You have passed the pre-approval amount test, and your pre-approved loan amount is"
                  }
                </div>
                <div className={"testAmount"}>{"₱ 20,000"}</div>
                <img
                  className={"loading"}
                  src={testAmountLoading}
                  alt="loading"
                />

                <div className={"download"}>{"OKPeso is downloading…"}</div>
                <div className={"end"}>
                  {
                    "Please find the OKPeso APK file that you just downloaded , click Install, and submit the information according to the app prompt."
                  }
                </div>
              </div>
            </div>
          </div>
        )}

        {loadingModal && (
          <div className={"loadingModal"}>
            <div className={"testMask"} />
            <div className={"content"}>
              {
                <div className={"infoModal"}>
                  <img
                    className={"loading"}
                    src={testAmountLoading}
                    alt="testAmountLoading"
                  />
                </div>
              }
            </div>
          </div>
        )}

        {userAgentVisible ? (
          <UserAgentConponent {...UserAgentConponentProps} />
        ) : null}
      </div>
    );
  }
}

export default TestAmount;
