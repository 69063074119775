import React, { useEffect, useRef, useState } from "react";
import InfoMessage from "./subcomponents/infoMessage";
import "./index.less";
import topLogo from "@/assets/landing/v1/logo@2x.png";
import coint1 from "@/assets/landing/v1/coint1@2x.png";
import coint2 from "@/assets/landing/v1/coint2@2x.png";
import coint3 from "@/assets/landing/v1/coint3@2x.png";
import meservice from "@/assets/landing/meservice.png";
import Amount from "./subcomponents/Amount";
import Block1 from "./subcomponents/Block1";
import Block2 from "./subcomponents/Block2";
import Block3 from "./subcomponents/Block3";
import Download from "./subcomponents/Download";
import { reportSys } from "@/utils/tools";
import ServiceModal from "./ServiceModal";

const Info = (prop) => {
  const [countObj, setCountObj] = useState({ second: "00", minute: "10" });
  const [redModal, setRedModal] = useState(false);
  const [showCount, setShowCount] = useState(true);
  const [serviceModal, setServiceModal] = useState(false);
  const [amountStep, setAmountStep] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    setRedModal(true);
    const redInv = localStorage.getItem("redInv");
    if (redInv) {
      clearInterval(parseInt(redInv));
    }
    let count10Min = 60 * 10;
    const newRedInv = setInterval(() => {
      count10Min--;
      if (count10Min <= 0) {
        setShowCount(false);
        clearInterval(newRedInv);
      } else {
        let minute = Math.floor(count10Min / 60);
        let second = count10Min - minute * 60;
        setCountObj({
          minute: minute < 10 ? "0" + minute : minute + "",
          second: second < 10 ? "0" + second : second + "",
        });
      }
    }, 1000);
    localStorage.setItem("redInv", newRedInv + "");
    reportSys("openRedApplyPopup");
    return () => {
      clearInterval(newRedInv);
    };
  }, []);
  const top1 = "Welcome to join us!";
  const top2 = "With 6.5 million Registered Users.";
  const topInfo = () => {
    return (
      <div className="top">
        <img src={topLogo} />
        <span className="top1">{top1}</span>
        <span className="top2">{top2}</span>
      </div>
    );
  };
  const handleGotIt = () => {
    reportSys("Getit-click");
    reportSys("openRedApplyPage");
    setRedModal(false);
    setAmountStep(true);
  };
  const renderRedModal = () => {
    const title = "limited seats";
    const amount = "₱5,000";
    const endStr = "End within";
    const gotIt = "GOT IT";
    return (
      <div className="redModal">
        <div className="redInfo">
          <img className="coint1" src={coint1} />
          <img className={["coint1", "coint12"].join(" ")} src={coint1} />
          <img className="coint1" src={coint1} />
          <img className="coint2" src={coint2} />
          <img className="coint3" src={coint3} />
          <img className={["coint11"].join(" ")} src={coint1} />

          <div className="infoTop">
            <div className="infoTopTitle">{title}</div>
            <div className="infoTopDesc">{amount}</div>
            <div className="infoTopEnd">{endStr}</div>
            {showCount && (
              <div className="infoTopEnd">{`${countObj.minute}:${countObj.second}`}</div>
            )}
          </div>
          <div className="gotIt" onClick={() => handleGotIt()}>
            {gotIt}
          </div>
        </div>
      </div>
    );
  };
  const gotoEnd = () => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleOpenService = (flag) => {
    if (flag) {
      setServiceModal(true);
      reportSys("ContactUs-click"); //打开客服弹框
    } else {
      setServiceModal(false);
    }
  };
  const { isMobile = true, downLoanUrl } = prop;
  return (
    <>
      <div className={["applyInfo", !isMobile && "applyInfoPc"].join(" ")}>
        <div className="nav">
          <div className={["service"].join(" ")} />
          <div>{"OKPeso"}</div>
          <img
            src={meservice}
            className={["service"].join(" ")}
            onClick={gotoEnd}
          />
        </div>
        <InfoMessage isMobile={isMobile} />
        {topInfo()}
        <Amount isMobile={isMobile} amountStep={amountStep} />
        <Block1 isMobile={isMobile} />
        <Block2 isMobile={isMobile} />

        <Block3
          isMobile={isMobile}
          handleOpenService={() => handleOpenService(true)}
        />
        <div ref={scrollRef} />
      </div>
      <Download
        isMobile={isMobile}
        countObj={countObj}
        downLoanUrl={downLoanUrl}
        showCount={showCount}
        nextPart={prop.handleNextPart}
        handleEnd={prop.handleEnd}
        type={prop.type}
      />
      {redModal && renderRedModal()}
      {serviceModal && <ServiceModal handleOpenService={handleOpenService} />}
    </>
  );
};
export default Info;
