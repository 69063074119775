import { Apply, Info, Result } from "../../components/Landing";
import { checkMobile, reportPage } from "../../utils/tools";
import { enquireScreen } from "enquire-js";

import React, { PureComponent } from "react";
import styles from "./index.less";

class LandingApply extends PureComponent {
  state = {
    userAgentVisible: false,
    testModal: false,
    count: 3,
    successModal: false,
    isMobile: true,
    step: 1,
  };

  props = this.props;
  componentDidMount() {
    // reportPage();
    let hasSubmit = localStorage.getItem("hasSubmit");
    let submitPhone = localStorage.getItem("submitPhone");
    if (hasSubmit) {
      this.setState({ step: 3 });
    } else if (submitPhone) {
      this.setState({ step: 2 });
    }
    this.setState({
      isMobile: checkMobile(),
    });
    enquireScreen((b) => {
      this.setState({
        isMobile: b == undefined ? false : !!b,
      });
    });
    fetch(window.location.origin + `/api/limit/test/page?a=1&k=15`, {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=UTF-8" },
      mode: "no-cors",
      cache: "default",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.ret === 0) {
          this.setState({
            downLoanUrl: res.data.downloadUrl,
          });
        }
      });
  }
  componentWillUnmount() {
    localStorage.removeItem("hasSubmit");
    localStorage.removeItem("step");
  }
  handleNextPart = () => {
    this.setState({ step: 2 });
  };
  handleLastPart = () => {
    this.setState({ step: 3 });
  };
  handlePrePart = () => {
    localStorage.removeItem("hasSubmit");
    localStorage.removeItem("submitPhone");
    localStorage.removeItem("step1");
    localStorage.removeItem("step2");
    localStorage.removeItem("step");
    this.setState({ step: 1 });
  };

  render() {
    const { isMobile, downLoanUrl, step } = this.state;
    return (
      <div className={["home", !isMobile && "homePc"].join(" ")}>
        {step == 1 && (
          <Info
            isMobile={isMobile}
            downLoanUrl={downLoanUrl}
            handleNextPart={this.handleNextPart}
            handleEnd={this.handleLastPart}
          />
        )}
        {step == 2 && (
          <Apply
            isMobile={isMobile}
            downLoanUrl={downLoanUrl}
            handlePrePart={this.handlePrePart}
            handleNextPart={this.handleLastPart}
          />
        )}
        {step == 3 && (
          <Result
            isMobile={isMobile}
            downLoanUrl={downLoanUrl}
            handlePrePart={this.handlePrePart}
          />
        )}
      </div>
    );
  }
}

export default LandingApply;
