import React, { useState } from "react";
import "./index.less";
import email from "@/assets/landing/email.png";
import server from "@/assets/landing/server.png";
import viber from "@/assets/landing/viber@2x.png";
import whatsapp from "@/assets/landing/whatsapp@2x.png";
import fb from "@/assets/landing/fb@2x.png";

import Message from "../Message";

import { reportSys } from "@/utils/tools";

const Server = (prop) => {
  const topTitle = "Customer Service";
  const phone = "Phone Service";
  const desc = "9:00 AM - 6:00 PM";
  const social = "Social Networks";
  const emailDesc = "Eamil Service";
  const viberStr = "9621546377";
  const whatsappStr = "09319533595";
  const emailStr = "service@okpeso.com";
  const fbUrl = "https://www.facebook.com/OKPesoHub/";
  const { isMobile = true, handleOpenService } = prop;
  const [msgObj, setMsgObj] = useState({ txt: "", type: "success" });
  const [showMsg, setShowMsg] = useState(false);

  const handleCopyClick = async (e, copyInfo, icon) => {
    e.preventDefault();
    try {
      const textToCopy = copyInfo;
      await navigator.clipboard.writeText(textToCopy);
      setMsgObj({ ...msgObj, txt: "Copied", icon });
      setShowMsg(true);
      if(copyInfo === viberStr){
        reportSys("socialNetworksViberCopy")
      }
      if(copyInfo === whatsappStr){
        reportSys("socialNetworksWhatsAppCopy")
      }
      setTimeout(() => {
        setShowMsg(false);
      }, 2000);
    } catch (err) {
      setMsgObj({ type: "error", txt: "Failed to copy text" });

      setShowMsg(true);
      setTimeout(() => {
        setShowMsg(false);
      }, 2000);
    }
  };
  const gotoFb = () => {
    reportSys("socialNetworksGotoFB");
    window.open(fbUrl);
  };

  return (
    <>
      <div className={["server", !isMobile && "serverPc"].join(" ")}>
        <div className={"sTitle"}>{topTitle}</div>
        <div className={"sContent"} onClick={handleOpenService}>
          <div>
            <div>{phone}</div>
            <div className={"sDesc"}>{desc}</div>
          </div>
          <img src={server} />
        </div>
        <div className={"sContent2"}>
          <div className={"sItem"}>
            <div>{social}</div>
            <div className={"icons"}>
              <img src={fb} onClick={() => gotoFb()} />
              <img
                src={whatsapp}
                onClick={(e) => handleCopyClick(e, whatsappStr, whatsapp)}
              />

              <img
                src={viber}
                onClick={(e) => handleCopyClick(e, viberStr, viber)}
              />
            </div>
          </div>
          <div className={"sItem"}>
            <div>{emailDesc}</div>
            <div
              className={"email"}
              onClick={(e) => handleCopyClick(e, emailStr, email)}
            >
              <img src={email} />
            </div>
          </div>
        </div>

        {showMsg && <Message infoObj={msgObj} />}
      </div>
    </>
  );
};
export default Server;
