import React, { useEffect, useState } from "react";
import "./index.less";

import { reportSys } from "@/utils/tools";
import Input from "./Input";
import BirthModal from "./BirthModal";
import Select from "./Select";
function generateCalendarArray(year, month) {
  const daysInMonth = new Date(year, month, 0).getDate();
  let calendarArray = [];
  let dayOfMonth = 1;
  for (let d = dayOfMonth; d <= daysInMonth; d++) {
    calendarArray.push({
      label: d,
      value: d < 10 ? "0" + d : d,
      bValue: d < 10 ? "d0" + d : "d" + d,
    });
  }

  return calendarArray;
}

const Step1 = (prop) => {
  useEffect(() => {
    reportSys("openRedApplyPage1");
    return () => {};
  }, []);
  const { isMobile = true, check = false, stepProp } = prop;
  const [addObjs, setAddObjs] = useState(null);
  const [showAddress, setShowAddress] = useState(false);
  const [address, setAddress] = useState([]);
  const [step1Obj, setStep1Obj] = useState({
    firstName: {
      name: "firstName",
      title: "First Name",
      desc: "Given name,include your suffix name",
    },
    middleNameOpt: {
      name: "middleNameOpt",
      title: "Middle Name",
      desc: "Enter middle name（Optional）",
      noReq: false,
    },
    lastName: {
      name: "lastName",
      title: "Last Name",
      desc: "Enter last name",
    },
    gender: {
      name: "gender",
      title: "Gender",
      desc: "Enter last name",
    },
    birthStr: {
      name: "birthStr",
      title: "Date Of Birth",
      desc: "Month / Day /Year",
      label: "",
      value: "",
    },
  });

  const onChange = (name, value, check) => {
    if (check) {
      stepProp.handleChange && stepProp.handleChange(name, value);
    }
  };
  const gotoSelect = (name) => {
    let endYear = new Date().getFullYear() - 18;
    let lastYear = endYear - 80;
    let addressTemp = [];
    for (let y = endYear; y > lastYear; y--) {
      let monthTemp = [];
      for (let m = 1; m < 13; m++) {
        monthTemp.push({
          value: m < 10 ? "0" + m : m,
          label: m,
          bValue: m < 10 ? "m0" + m : "m" + m,

          children: generateCalendarArray(y, m),
        });
      }
      addressTemp.push({
        value: y,
        label: y,
        bValue: y,
        children: monthTemp,
      });
    }
    setAddress(addressTemp);
    setShowAddress(true);
  };
  const onSelect = (objItem = null, values = null) => {
    if (values) {
      let step1ObjTemp = step1Obj;
      let objTemp = step1ObjTemp[objItem.name];
      objTemp.label =
        values.addrProvince.value +
        "/" +
        values.addrCity.value +
        "/" +
        values.addrRegion.value;
      objTemp.value = values.addrRegion.value;
      setAddObjs(values);
      setStep1Obj(step1ObjTemp);
      stepProp.handleBirthChange && stepProp.handleBirthChange(values);
      setShowAddress(false);
    } else {
      setShowAddress(false);
    }
  };
  const title = "Complete the information";
  const desc = "Get a higher credit";
  const iProp = {
    check: stepProp.check,
    onChange,
  };
  const sProp = {
    check: stepProp.check,
    gotoSelect,
  };
  const smAddressProp = {
    onSelect,
    addObjs,
    address,
  };
  //maxLength minLength
  return (
    <>
      <div className={["step1", !isMobile && "step1Pc"].join(" ")}>
        <div className="s1Title">{title}</div>
        <div className="s1Desc">{desc}</div>
        <Input
          obj={step1Obj.firstName}
          {...iProp}
          inputProp={{ maxLength: 20 }}
        />
        <Input
          obj={step1Obj.middleNameOpt}
          {...iProp}
          inputProp={{ maxLength: 20 }}
        />
        <Input
          obj={step1Obj.lastName}
          {...iProp}
          inputProp={{ maxLength: 20 }}
        />
        <Input obj={step1Obj.gender} {...iProp} type={"select"} />
        {/* <Input obj={step1Obj.gender} {...iProp} /> */}
        <Select stepObj={step1Obj.birthStr} {...sProp} />
      </div>
      {showAddress && <BirthModal {...smAddressProp} />}
    </>
  );
};
export default Step1;
