import { canGoMarket, reportDownload, reportSys } from "@/utils/tools";
import React, { useEffect, useState } from "react";
import "./apply.less";
import { dict } from "./data.js";
import Step1 from "./applycomponents/Step1";
import acoint from "@/assets/landing/v1/acoint.png";
import Step2 from "./applycomponents/Step2";
import Step3 from "./applycomponents/Step3";
import Message from "./Message";
import homeImg from "@/assets/landing/home.png";

const Apply = (prop) => {
  const [count5MinObj, setCount5MinObj] = useState({
    second: "00",
    minute: "05",
  });
  const [step, setStep] = useState(1);
  const [check, setCheck] = useState(false);
  const [msgObj, setMsgObj] = useState({ txt: "", type: "loading" });
  const [showMsg, setShowMsg] = useState(false);
  const [countEnd, setCountEnd] = useState(true);
  // const [stepEnd, setStepEnd] = useState('')
  const [stepValues, setStepValues] = useState({
    step1: {
      firstName: "",
      middleNameOpt: "",
      lastName: "",
      gender: "",
      birthYear: "",
      birthMonth: "",
      birthDay: "",
    },
    step2: {
      addrRegion: "",
      addrRegionDesc: "",
      addrProvince: "",
      addrProvinceDesc: "",
      addrCity: "",
      addrCityDesc: "",
      addrDetail: "",
      addrTime: "",
      addrTimeDesc: "",
      religion: "",
      religionDesc: "",
      martialStatus: "",
      martialStatusDesc: "",
      housingStatus: "",
      housingStatusDesc: "",
    },
    step3: {
      email: "",
      phoneOpt: "",
      fbUsername: "",
      education: "",
      educationDesc: "",
      salaryDay1: "",
      salaryDay1Desc: "",
      salaryDay2: "",
      salaryDay2Desc: "",
    },
  });

  useEffect(() => {
    // localStorage.setItem("redInv", newRedInv + "");
    // initMin5();
    const stepStr = localStorage.getItem("step");
    console.log('stepStr || "1"', stepStr);
    let stepTemp = parseInt(stepStr || "1");
    setStep(stepTemp);
    if (stepTemp === 3) {
      initMin5();
    }
    return () => {
      const redInv = localStorage.getItem("redInv");

      if (redInv) {
        clearInterval(parseInt(redInv));
      }
    };
  }, []);
  const initMin5 = () => {
    const redInv = localStorage.getItem("redInv");
    if (redInv) {
      clearInterval(parseInt(redInv));
    }
    let count5Min = 60 * 5;
    const newRedInv = setInterval(() => {
      count5Min--;
      let minute5 = Math.floor(count5Min / 60);
      let second5 = count5Min - minute5 * 60;
      if (count5Min <= 0) {
        clearInterval(parseInt(redInv));
        setCountEnd(false);
      } else {
        setCount5MinObj({
          minute: minute5 < 10 ? "0" + minute5 : minute5 + "",
          second: second5 < 10 ? "0" + second5 : second5 + "",
        });
      }
    }, 1000);
    localStorage.setItem("redInv", newRedInv + "");
  };

  const { isMobile = true, handlePrePart } = prop;
  const nextStr = "next";

  const handleNext = () => {
    let values = stepValues[`step${step}`];
    const stepReport = {
      step1: "RedApplyPage1-next-click",
      step2: "RedApplyPage2-LastStep-click",
      step3: "RedApplyPage3—download-click",
    };
    reportSys(stepReport[`step${step}`]);
    let keys = Object.keys(values);
    let pass = true;
    keys.forEach((key) => {
      if (key.indexOf("Opt") === -1 && !values[key]) {
        pass = false;
      }
    });
    if (pass) {
      if (step === 3) {
        let phone = localStorage.getItem("submitPhone");
        const step1 = localStorage.getItem("step1");
        let step1Temp = JSON.parse(step1);
        step1Temp["middleName"] = step1Temp.middleNameOpt;
        delete step1Temp.middleNameOpt;
        step1Temp["email"] = values.email;
        step1Temp["gender"] = parseInt(step1Temp.gender);
        step1Temp["phone"] = values.phoneOpt;
        delete step1Temp.phoneOpt;
        step1Temp["fbUsername"] = values.fbUsername;
        const step2 = localStorage.getItem("step2");
        let step2Temp = JSON.parse(step2);
        step2Temp["education"] = values.education;
        step2Temp["religion"] =
          step2Temp.religion === -1 ? 0 : step2Temp.religion;

        step2Temp["educationDesc"] = values.educationDesc;
        step2Temp["salaryDay1"] = values.salaryDay1;
        step2Temp["salaryDay1Desc"] = values.salaryDay1Desc;
        step2Temp["salaryDay2"] = values.salaryDay2;
        step2Temp["salaryDay2Desc"] = values.salaryDay2Desc;
        setShowMsg(true);
        fetch(window.location.origin + `/api/loan/audit/landing/page`, {
          method: "POST",
          headers: { "Content-Type": "application/json;charset=UTF-8" },
          // mode:'no-cors',
          cache: "default",
          body: JSON.stringify({
            phone,
            auditParam: JSON.stringify({
              name: JSON.stringify(step1Temp),
              address: JSON.stringify(step2Temp),
            }),
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res && res.ret === 0) {
              setTimeout(() => {
                // // Toast.hide()
                // let count = 3;
                // let nSInv = setInterval(() => {
                //   count--;
                //   setMsgObj({ txt: count + "s", type: "wait" });
                //   if (count === 0) {
                //     setShowMsg(false);
                //     clearInterval(nSInv);
                //     setTimeout(() => {
                //       localStorage.removeItem("step1");
                //       localStorage.removeItem("step2");
                //       localStorage.removeItem("step");
                //       localStorage.removeItem("submitPhone");
                //       localStorage.setItem("hasSubmit", "1");

                //       prop.handleNextPart && prop.handleNextPart();
                //     }, 1000);
                //   }
                // }, 1000);
                setShowMsg(false);
                // clearInterval(nSInv);
                setTimeout(() => {
                  localStorage.removeItem("step1");
                  localStorage.removeItem("step2");
                  localStorage.removeItem("step");
                  localStorage.removeItem("submitPhone");
                  localStorage.setItem("hasSubmit", "1");

                  prop.handleNextPart && prop.handleNextPart();
                }, 1000);
              }, 500);
            } else {
              setMsgObj({ txt: res.msg, type: "error" });
              setTimeout(() => {
                setShowMsg(false);
              }, 2000);
            }
          });
      } else {
        setCheck(false);

        let lastStep = step + 1;
        setStep(lastStep);
        localStorage.setItem(`step${step}`, JSON.stringify(values));
        localStorage.setItem("step", lastStep);
        if (lastStep == 3) {
          initMin5();
        }
      }
    } else {
      setCheck(true);
    }
  };
  const handleChange = (name, value) => {
    let tempStep = stepValues;
    console.log("name", name, value);
    let values = tempStep[`step${step}`];
    values[name] = value;
    console.log("tempStep", tempStep);
    setStepValues(tempStep);
  };
  const handleSelectChange = (name, selectValue) => {
    const { label, value } = selectValue;
    let tempStep = stepValues;
    console.log("name", name, values);
    let values = tempStep[`step${step}`];
    values[name] = value;
    values[name + "Desc"] = label;
    console.log("tempStep", tempStep);
    setStepValues(tempStep);
  };
  const handleAddressChange = (values) => {
    let stepValuesTemp = stepValues;
    let step2 = stepValuesTemp.step2;
    const { addrRegion, addrProvince, addrCity } = values;
    step2["addrRegion"] = addrRegion.value;
    step2["addrRegionDesc"] = addrRegion.label;
    step2["addrProvince"] = addrProvince.value;
    step2["addrProvinceDesc"] = addrProvince.label;
    step2["addrCity"] = addrCity.value;
    step2["addrCityDesc"] = addrCity.label;
    setStepValues(stepValuesTemp);
  };
  const handleBirthChange = (values) => {
    let stepValuesTemp = stepValues;
    let step1 = stepValuesTemp.step1;
    const { addrRegion, addrProvince, addrCity } = values;
    step1["birthYear"] = String(addrRegion.label);
    step1["birthMonth"] = String(addrProvince.label);
    step1["birthDay"] = String(addrCity.label);
    // let birthStr =
    //   addrRegion.value + "-" + addrProvince.value + "-" + addrCity.value;
    // step1["birthStr"] = birthStr;
    // step1["birth"] = new Date(birthStr).valueOf();

    setStepValues(stepValuesTemp);
  };
  const handleJobChange = (values) => {
    let stepValuesTemp = stepValues;
    let step3 = stepValuesTemp.step3;
    const { addrRegion, addrProvince } = values;
    step3["salaryDay1"] = addrRegion.value;
    step3["salaryDay1Desc"] = addrRegion.label;
    step3["salaryDay2"] = addrProvince.value;
    step3["salaryDay2Desc"] = addrProvince.label;
    setStepValues(stepValuesTemp);
  };
  const stepProp = {
    stepValues,
    step,
    check,
    handleChange,
    handleSelectChange,
    handleAddressChange,
    handleBirthChange,
    handleJobChange,
    dict,
  };
  const amounts = ["₱5,500", "₱10,500", "₱20,000"];
  const stepEnd = `Download the app within ${count5MinObj.minute}:${count5MinObj.second} to get your loan.`;
  const stepEndLast = "Download the app to get your loan.";

  return (
    <>
      <div className={["applyForm", !isMobile && "applyFormPc"].join(" ")}>
        <div className="nav">
          <img
            src={homeImg}
            className={["service"].join(" ")}
            onClick={handlePrePart}
          />
          <div>{"OKPeso"}</div>
          <div className={["service"].join(" ")} />
        </div>
        <div className="formInfo">
          <div className="formTop">
            <div className={["ftItem", step >= 1 && "ftItemOn"].join(" ")}>
              <span>{amounts[0]}</span>
              {step === 1 && <img className="acoint" src={acoint} />}
            </div>

            <div className={["ftItem", step >= 2 && "ftItemOn"].join(" ")}>
              <span>{amounts[1]}</span>
              {step === 2 && <img className="acoint" src={acoint} />}
            </div>
            <div className={["ftItem", step >= 3 && "ftItemOn"].join(" ")}>
              <span>{amounts[2]}</span>
              {step === 3 && <img className="acoint" src={acoint} />}
            </div>
          </div>
          {step === 1 && <Step1 stepProp={stepProp} />}
          {step === 2 && <Step2 stepProp={stepProp} />}
          {step === 3 && <Step3 stepProp={stepProp} />}
        </div>
        {step < 4 && (
          <div
            className={["next", step === 3 && "stepEnd"].join(" ")}
            onClick={handleNext}
          >
            {step == 3 ? (countEnd ? stepEnd : stepEndLast) : nextStr}
          </div>
        )}
      </div>
      {showMsg && <Message infoObj={msgObj} />}
    </>
  );
};
export default Apply;
