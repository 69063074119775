import React, { Component } from "react";
import Home from "./Home";
import CreditAgreement from "./Page/CreditAgreement";
import PrivacyAgreement from "./Page/PrivacyAgreement";
import ServiceAgreement from "./Page/ServiceAgreement";
import PushInfo from "./Page/PushInfo";
import TestAmount from "./Page/TestAmount";
import ProV1 from "./Page/langding/proV1";
import demoTest from "./Page/demo/test";
import testPackage from "./Page/demo/testPackage";
import TestWLAmount from "./Page/TestWLAmount";
import Redfb from "./Page/Redfb";
import Redtt from "./Page/Redtt";
import Redwl from "./Page/Redwl";
import LangdingApply from "./Page/langdingApply";
import LangdingApplySS from "./Page/langdingApply/indexss";
import LangdingApplyGG from "./Page/langdingApply/indexgg";
import { FAQ } from "./Home/FAQ";
import OppoH5 from "./Page/oppoH5";

import { Router, Route, Switch, Redirect } from "react-router-dom";

import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory();

class App extends Component {
  componentDidMount() {
    localStorage.removeItem("isRegister");
  }
  render() {
    console.log("----App----");
    return (
      <Router history={browserHistory}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/creditAgreement" component={CreditAgreement} />
          <Route exact path="/privacyAgreement" component={PrivacyAgreement} />
          <Route exact path="/serviceAgreement" component={ServiceAgreement} />
          <Route exact path="/thirdInfo" component={PushInfo} />
          <Route exact path="/testAmount" component={TestAmount} />
          <Route exact path="/testAmount1" component={TestAmount} />
          <Route exact path="/testAmount2" component={TestAmount} />
          <Route exact path="/testAmount3" component={TestAmount} />
          <Route exact path="/testAmount4" component={TestAmount} />
          <Route exact path="/testAmount5" component={TestAmount} />
          <Route exact path="/testWLAmount" component={TestWLAmount} />
          <Route exact path="/proV1" component={ProV1} />
          <Route exact path="/proV2" component={ProV1} />
          <Route exact path="/proV3" component={ProV1} />
          <Route exact path="/proV4" component={ProV1} />
          <Route exact path="/proV5" component={ProV1} />
          <Route exact path="/testService" component={demoTest} />
          <Route exact path="/testPackage" component={testPackage} />

          <Route exact path="/redfb" component={Redfb} />
          <Route exact path="/redtt" component={Redtt} />
          <Route exact path="/redwl" component={Redwl} />
          <Route exact path="/redapplyss" component={LangdingApplySS} />
          <Route exact path="/redapplygg" component={LangdingApplyGG} />
          <Route exact path="/redapplytt" component={LangdingApply} />
          <Route exact path="/redapplyfb" component={LangdingApply} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/oppoH5" component={OppoH5} />

          <Route path="*" component={() => <Redirect to="/" />} />
        </Switch>
      </Router>
    );
  }
}

export default App;
