import React, { useState, useEffect } from "react";
import "./index.less";
import { reportSys } from "@/utils/tools";

import Input from "./Input";
import SelectModal from "./SelectModal";
import Select from "./Select";
import Message from "../Message";
import AddressModal from "./AddressModal";

const Step2 = (prop) => {
  const { isMobile = true, check = false, stepProp } = prop;
  const [msgObj, setMsgObj] = useState({ txt: "", type: "loading" });
  const [showMsg, setShowMsg] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [step2Obj, setStep2Obj] = useState({
    addObjs: {
      name: "addObjs",
      title: "Current address",
      desc: "Please choose",
      label: "",
      value: "",
    },
    addrDetail: {
      name: "addrDetail",
      title: "Detailed address",
      desc: "Please enter",
    },
    addrTime: {
      name: "addrTime",
      title: "How long have you been living in your Current address?",
      desc: "Please choose",
    },
    religion: {
      name: "religion",
      title: "Religion",
      desc: "Please choose",
      label: "",
      value: "",
    },
    martialStatus: {
      name: "martialStatus",
      title: "Marital Status",
      desc: "Please choose",
      label: "",
      value: "",
    },
    housingStatus: {
      name: "housingStatus",
      title: "Housing Status",
      desc: "Please choose",
      label: "",
      value: "",
    },
  });
  const [list, setList] = useState([]);
  const [stepObj, setStepObj] = useState({});
  const [addObjs, setAddObjs] = useState(null);
  const [address, setAddress] = useState([]);
  const initAddress = () => {
    setShowMsg(true);
    fetch(window.location.origin + `/api/loan/audit/location/tree?appId=1`, {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=UTF-8" },
      // mode:'no-cors',
      cache: "default",
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("res", res);

        if (res && res.ret === 0) {
          setAddress(res.data);
          setTimeout(() => {
            setShowMsg(false);
            setShowAddress(true);
          }, 500);
        } else {
          setMsgObj({ txt: res.msg, type: "error" });
          setTimeout(() => {
            setShowMsg(false);
          }, 2000);
        }
      });
  };
  useEffect(() => {
    reportSys("openRedApplyPage2");
    return () => {};
  }, []);
  const { dict = {} } = stepProp;

  const onChange = (name, value, check) => {
    if (check) {
      stepProp.handleChange && stepProp.handleChange(name, value);
    }
  };
  const onSelect = (objItem = null, values = null) => {
    if (objItem) {
      let step2ObjTemp = step2Obj;
      if (objItem.name === "addObjs") {
        if (values) {
          let objTemp = step2ObjTemp[objItem.name];
          objTemp.label =
            values.addrRegion.label +
            values.addrProvince.label +
            values.addrCity.label;
          objTemp.value = values.addrRegion.value;
          setAddObjs(values);
          stepProp.handleAddressChange && stepProp.handleAddressChange(values);
          setShowAddress(false);
          setStep2Obj(step2ObjTemp);
        } else {
          setShowAddress(false);
        }
      } else {
        let objTemp = step2ObjTemp[objItem.name];
        objTemp.label = values.label;
        objTemp.value = values.value;
        setStep2Obj(step2ObjTemp);
        stepProp.handleSelectChange &&
          stepProp.handleSelectChange(objItem.name, values);
      }
    }
    setShowSelect(false);
  };

  const gotoSelect = (name) => {
    if (name && name === "addObjs") {
      if (address.length > 0) {
        setShowAddress(true);
      } else {
        initAddress();
      }
    } else if (name && dict[name]) {
      setList(dict[name]);
      setStepObj(step2Obj[name]);
      setShowSelect(true);
    }
  };
  const title = "The current credit is ₱10,500";
  const desc = "Continue to increase the credit";
  const sProp = {
    check: stepProp.check,
    gotoSelect,
  };
  const inputProp = {
    check: stepProp.check,
    onChange,
  };
  const smProp = {
    onSelect,
    list,
    stepObj,
  };
  const smAddressProp = {
    onSelect,
    address,
    addObjs,
  };
  return (
    <>
      <div className={["step1", !isMobile && "step1Pc"].join(" ")}>
        <div className="s1Title">{title}</div>
        <div className="s1Desc">{desc}</div>
        <Select stepObj={step2Obj.addObjs} {...sProp} />
        <Input
          obj={step2Obj.addrDetail}
          {...inputProp}
          type={"all"}
          inputProp={{ maxLength: 240 }}
        />
        <Select stepObj={step2Obj.addrTime} {...sProp} />
        <Select stepObj={step2Obj.religion} {...sProp} />
        <Select stepObj={step2Obj.martialStatus} {...sProp} />
        <Select stepObj={step2Obj.housingStatus} {...sProp} />
      </div>
      {showSelect && <SelectModal {...smProp} />}
      {showAddress && <AddressModal {...smAddressProp} />}
      {showMsg && <Message infoObj={msgObj} />}
    </>
  );
};
export default Step2;
