import React, { useEffect, useRef, useState } from "react";
import "./result.less";
import topLogo from "@/assets/landing/v1/logo@2x.png";
import b31 from "@/assets/landing/v1/b31@2x.png";
import resultImg from "@/assets/landing/result.png";

import homeImg from "@/assets/landing/home.png";
import server from "@/assets/landing/server.png";

import { reportSys } from "@/utils/tools";
import { canGoMarket, reportDownload } from "../../utils/tools";
import ServiceModal from "./ServiceModal";

const Result = (prop) => {
  const { type } = prop;
  const [userAgentVisible, setUserAgentVisible] = useState(false);
  const [serviceModal, setServiceModal] = useState(false);
  const hasGoogle = (type)=>{
    return type && (type === "ss" || type === "gg")
  }
  useEffect(() => {
    if (hasGoogle(type)) {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("event", "conversion", {
        send_to: "AW-11327427724/j0z7CNmJvOoZEIypq5kq",
      });
      console.log("gtag conversion");
    }
    setTimeout(() => {
      initDownloan();
    }, 500);

    return () => {};
  }, [type]);
  const initDownloan = () => {
    reportSys("openRedApplyDownloadPage");
    let deviceType = canGoMarket();
    if (deviceType) {
      setUserAgentVisible(true);
    } else {
      //旧逻辑
      reportDownload();
      localStorage.removeItem("hasSubmit");
      localStorage.removeItem("step");
      // window.location.href = prop.downLoanUrl;
      window.open(prop.downLoanUrl, "_blank");
    }
  };
  const top1 = "OKPeso-Online Safe Loan";
  const topInfo = () => {
    const top1Title = "You are really excellent!";
    const top2Title =
      "Install the app and register to activate, then you can recive a loan.";
    return (
      <div className="rsTop1Title">
        <div className="top1Title">{top1Title}</div>
        <div className="top2Title">{top2Title}</div>
      </div>
    );
  };
  const downDesc = "OKPeso is downloading";
  const centerDesc = "help center";
  const { isMobile = true, downLoanUrl, handlePrePart } = prop;

  const handleOpenService = (flag) => {
    if (flag) {
      setServiceModal(true);
      reportSys("openRedApplyDownloadPage-ContactUs-click");
    } else {
      setServiceModal(false);
    }
  };
  return (
    <>
      <div className={["resultInfo", !isMobile && "resultInfoPc"].join(" ")}>
        <div className="nav">
          <img
            src={homeImg}
            className={["service"].join(" ")}
            onClick={handlePrePart}
          />
          <div>{"OKPeso"}</div>
          <div className={["service"].join(" ")} />
        </div>
        <div className="reTop">
          <img src={topLogo} className="topLogo" />
          <div className="reTopName"> 
            <div className="reTopName1">{top1}</div>
            <div className="reTopDesc">
              <img src={b31} />
              {"4.9"}
            </div>
          </div>
        </div>
        {topInfo()}
        <img src={resultImg} className="resultImg" />
        <div className="downDesc">{downDesc}</div>

        <div className="reServerDesc" onClick={() => handleOpenService(true)}>
          <img src={server} />
          <div className="reServer">{centerDesc}</div>
        </div>
      </div>
      {userAgentVisible && <UserAgentConponents {...UserAgentConponentProps} />}
      {serviceModal && <ServiceModal handleOpenService={handleOpenService} />}
    </>
  );
};
export default Result;
