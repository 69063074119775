import React, { useState } from "react";
import "./index.less";
import select from "@/assets/landing/select.png";

const errors = {
  empty: "Please fill the blanks",
};
const Select = (prop) => {
  const { isMobile = true, stepObj = {}, check = false, gotoSelect } = prop;

  return (
    <>
      <div
        className={["aInput", !isMobile && "aInputPc"].join(" ")}
        onClick={() => gotoSelect(stepObj.name)}
      >
        <div className={"aTitle"}>{stepObj.title}</div>
        <div className={"aSelect"}>
          <div className={["aNoValue", !!stepObj.value && "sValue"].join(" ")}>
            {stepObj.label || stepObj.desc}
          </div>
          <img src={select} />
        </div>
        {/* <input className={"aValue"} placeholder={obj.desc} onChange={onChange} /> */}
        {check && !stepObj.value && (
          <div className="aInputError">{errors.empty}</div>
        )}
      </div>
    </>
  );
};
export default Select;
