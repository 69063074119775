import React, { useEffect, useState } from "react";
import styles from "./index.less";
import tip1 from "@/assets/landing/v1/tip1@2x.png";

import tip2 from "@/assets/landing/v1/tip2@2x.png";
function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const Amount = (prop) => {
  const { amountStep } = prop;
  const topTitle = "MAX Credit Amount";
  const [moneyV1, setMoneyV1] = useState(0);
  const [inv, setInv] = useState(null);
  useEffect(() => {
    if (amountStep) {
      initCount();
    }
    return () => {
      clearInterval(inv);
    };
  }, [amountStep]);

  const initCount = () => {
    let moneyV1Temp = 0;
    const invTemp = setInterval(() => {
      moneyV1Temp = moneyV1Temp + 3500;
      if (moneyV1Temp > 30000) {
        setMoneyV1(amount);
        clearInterval(inv);
      } else {
        setMoneyV1(formatNumber(moneyV1Temp));
      }
    }, 50);
    setInv(invTemp);
  };

  const title1 = "PHP";
  const amount = "30,000";
  const { isMobile = true } = prop;
  return (
    <div className={["applyAmount", !isMobile && "applyAmountPc"].join(" ")}>
      <div className={"amountTop"}>
        <img src={tip1} />
        <span>{topTitle}</span>
        <img src={tip2} />
      </div>
      <div className={"content"}>
        <div className={"contentTop"}>
          <span>{title1}</span>
          <div className={"moneyV1"}>{moneyV1}</div>
          <span className={"title"}>{title1}</span>
        </div>
      </div>
    </div>
  );
};
export default Amount;
