import React from "react";
import "./cardItem.less"
export const CardItem = (props) => {
  const { id,imgs, name, content, amount ,top ,left,right,bottom,imgSize,itemSize } = props;
  var size = imgSize ? imgSize : 5;
  var itemWidth = itemSize === 'large' ? 14 : 11;

  return (
    <div id={id} className="card-container" style={{top:`${top}vw` ,left:`${left}vw` ,right:`${right}vw` ,bottom:`${bottom}vw`, width:`${itemWidth}vw`}}>
      <img style={{width: `${size}vw` ,height:`${size}vw`}} src={imgs} alt="imgs"/>
      <div className="card-item">
        <h3>@{name}</h3>
        <span>{/* Fast arival, simple process, recommended! */}
        {content}
        </span>
        <div className="card-borrow">
          <span className="small">PHP</span>
          <span className="large">{amount}</span>
          <p>Loan Amount</p>
        </div>
      </div>
    </div>
  );
};
