import React, { useEffect, useState } from "react";
import "./index.less";
import selectClose from "@/assets/landing/selectClose.png";

const JobModal = (prop) => {
  const { isMobile = true, value = null, address, addObjs = null } = prop;
  const [list, setList] = useState([]);
  const [list1, setList1] = useState([]);
  const [list2, setList2] = useState([]);
  const [currentIdx, setCurrentIdx] = useState(0);
  const [addrRegion, setAddrRegion] = useState({ desc: "Pay Day1" });
  const [addrProvince, setAddrProvince] = useState({ desc: "Pay Day2" });
  const [stepObj, setStepObj] = useState({});
  const title = "SalaryDay";
  useEffect(() => {
    if (addObjs) {
      setAddrRegion(addObjs.addrRegion || { desc: "Choose Region" });
      setAddrProvince(addObjs.addrProvince || { desc: "Choose Province" });
      setStepObj(addObjs.addrProvince);
      initList(-1, addObjs);
    } else {
      initList();
    }

    return () => {
      setList([]);
    };
  }, address);
  const initList = (cIdx = 0, value = null) => {
    let temp1 = [];
    let temp2 = [];
    let temp3 = [];
    let addrRegionTemp = {};
    let addrProvinceTemp = {};
    if (value) {
      addrRegionTemp = value.addrRegion;
      addrProvinceTemp = value.addrProvince || {};
    }
    console.log("value");
    address.forEach((item) => {
      let addrRegionInfo = {
        value: item.value,
        label: item.label,
        bValue: item.bValue,
      };
      temp1.push(addrRegionInfo);
      if (addrRegionTemp.value) {
        if (item.value === addrRegionTemp.value) {
          let children = item.children;
          children.forEach((province) => {
            addrRegionInfo = {
              value: province.value,
              label: province.label,
              bValue: province.bValue,
            };
            temp2.push(addrRegionInfo);
          });
        }
      }
    });

    switch (cIdx) {
      case 0:
        setList(temp1);
        setList1(temp1);
        break;
      case 1:
        setList(temp2);
        setList2(temp2);
        break;

      case -1:
        setList1(temp1);
        setList2(temp2);
        setList(temp2);
        break;
      default:
        break;
    }
    setCurrentIdx(cIdx > -1 ? cIdx : 1);
  };
  const onTabSelect = (currentIdx) => {
    switch (currentIdx) {
      case 0:
        setStepObj(addrRegion);
        setList(list1);
        break;
      case 1:
        setStepObj(addrProvince);
        setList(list2);
        break;

      default:
        break;
    }
    setCurrentIdx(currentIdx);
  };
  const onSelect = (item) => {
    setStepObj(item);

    let tempItem = {};
    let addrRegionTemp, addrProvinceTemp;
    switch (currentIdx) {
      case 0:
        setList([]);
        setList2([]);
        setAddrRegion({});
        setAddrProvince({ desc: addrProvince.desc });
        addrRegionTemp = { ...addrRegion, ...item };
        setAddrRegion(addrRegionTemp);
        tempItem = { addrRegion: addrRegionTemp };
        break;

      case 1:
        setList2([]);
        addrRegionTemp = addrRegion;
        addrProvinceTemp = { ...addrProvince, ...item };
        setAddrProvince(addrProvinceTemp);

        break;
      default:
        break;
    }
    if (currentIdx < 1) {
      setTimeout(() => {
        initList(currentIdx + 1, tempItem);
      }, 200);
    } else {
      prop.onSelect &&
        prop.onSelect(
          { name: "salaryDay" },
          {
            addrRegion: addrRegionTemp,
            addrProvince: addrProvinceTemp,
          }
        );
    }
  };
  return (
    <div className={["aSelectModal", !isMobile && "aSelectModalPc"].join(" ")}>
      <div className={"asmInfo"}>
        <div className={"asmHeader"}>
          <div className={"asmTxt"}>{title}</div>
          <img
            onClick={() => prop.onSelect({ name: "salaryDay" })}
            src={selectClose}
          />
        </div>
        <div className={["asmLine", "asmLineAddressMore"].join(" ")}>
          <div
            className={["asmsLabel", !!addrRegion.label && "asmsLabelOn"].join(
              " "
            )}
            onClick={() => onTabSelect(0)}
          >
            {addrRegion.label || addrRegion.desc}
          </div>
          {!!addrRegion.label && (
            <div
              className={[
                "asmsLabel",
                !!addrProvince.label && "asmsLabelOn",
              ].join(" ")}
              onClick={() => onTabSelect(1)}
            >
              {addrProvince.label || addrProvince.desc}
            </div>
          )}
        </div>
        <div className={["asmList", "asmListAddress"].join(" ")}>
          {list &&
            list.length > 0 &&
            list.map((item) => (
              <div
                className={[
                  "asmItem",
                  stepObj.bValue &&
                    stepObj.bValue === item.bValue &&
                    "asmItemOn",
                ].join(" ")}
                onClick={() => onSelect(item)}
              >
                {item.label}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default JobModal;
