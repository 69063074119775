import React from "react";
import { enquireScreen } from "enquire-js";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});
class Test extends React.PureComponent {
  state = { isMobile };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
  }
  launchFacebook() {
    // 定义Facebook的URL Scheme
    var fbUrl = "fb://profile/100068937856647"; // 示例URL，可以替换为实际的用户ID或页面ID

    // 检测是否支持URL Scheme
    // function canOpenURL(url) {
    //   // 这里需要根据实际环境使用相应的方法
    //   // 例如在移动端可以使用navigator.userAgent或者其他方法来判断
    //   // 此处仅提供一个简单的逻辑判断
    //   return /iPhone|iPad|iPod/.test(navigator.userAgent) ? true : false;
    // }

    // 如果设备支持URL Scheme，则打开
    if (isMobile) {
      window.open(fbUrl, "_blank");
    } else {
      // 如果设备不支持，则打开Facebook网页版
      // window.location.href = "https://www.facebook.com/OKPesoHub"; // 示例URL，可以替换为实际的用户或页面的网页链接
      window.open("https://www.facebook.com/OKPesoHub/");
    }
  }
  launchWhatsApp() {
    //这里的PHONE NUMBER是你想要直接联系的matsApp用户的电话号
    //!如果你需要包含国家代码，可以把它加在号码前面，比如:+5512
    var whatsapp_url = "whatsapp://send?phone=639052553180";
    //检查设备是否支持URLschem
    if (isMobile) {
      console.log("window");
      window.location.href = whatsapp_url;
    } else {
      //在桌面设备上，可能需要打开matsApp的网页版或提示用户手
      window.open("https://web.whatsapp.com/send?phone=639052553180");
    }
  }
  render() {
    return (
      <div>
        <div
          style={{
            margin: 3,
            padding: 5,
            border: "1px solid blue",
            color: "blue",
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={() => this.launchFacebook()}
        >
          <span
            style={{
              color: "blue",
            }}
          >
            Launch Facebook
          </span>
        </div>
        <div
          style={{
            margin: 3,
            padding: 5,
            border: "1px solid green",
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={() => this.launchWhatsApp()}
        >
          <span
            style={{
              color: "green",
            }}
          >
            Launch whatsapp
          </span>
        </div>
        <div
          style={{
            margin: 3,
            padding: 5,
            border: "1px solid black",
            textAlign: "center",
          }}
        >
          <a
            style={{
              color: "black",
            }}
            href="tel:9052553180"
          >
            call
          </a>
        </div>
        <div
          style={{
            margin: 3,
            padding: 5,

            border: "1px solid red",
            textAlign: "center",
          }}
        >
          <a
            style={{
              color: "red",
            }}
            href="mailto:service@okpeso.com?subject=''"
            target={"_blank"}
          >
            send email
          </a>
        </div>
      </div>
    );
  }
}
export default Test;
