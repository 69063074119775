import React, { useState, useEffect } from "react";
import { CardItem } from "../components/CardItem";
// import a1 from "../assets/imgs/home/borrow/@AUSTRIA.png";
// import a2 from "../assets/imgs/home/borrow/@GAMBOA.png";
// import a3 from "../assets/imgs/home/borrow/@QUEZON.png";
// import a4 from "../assets/imgs/home/borrow/@ANUDON.png";
// import a5 from "../assets/imgs/home/borrow/@JOHNPHILIP.png";
import a6 from "../assets/imgs/home/borrow/BACAO.png";
import a7 from "../assets/imgs/home/borrow/ENRIQUEZ.png";
import a8 from "../assets/imgs/home/borrow/GERONA.png";
import a9 from "../assets/imgs/home/borrow/GODITO.png";
import a10 from "../assets/imgs/home/borrow/JOHNPHILIP.png";
import a11 from "../assets/imgs/home/borrow/MOVIDA.png";
import a12 from "../assets/imgs/home/borrow/NAVARRO.png";
import a13 from "../assets/imgs/home/borrow/ROJAS.png";
import a14 from "../assets/imgs/home/borrow/SORIANO.png";
import a15 from "../assets/imgs/home/borrow/VILLANUEVA.png";
import "./static/cardSelection.less";
import { sleep } from "../utils/common";

export const CardSelection = () => {
  const dataSource = [
    {
      name: "BACAO",
      imgs: a6,
      content: "Fast arrival, simple process, recommended!",
      amount: 5400,
    },
    {
      name: "ENRIQUEZ",
      imgs: a7,
      content: "Interest rate is relatively low, which makes my…",
      amount: 7600,
    },
    {
      name: "GERONA",
      imgs: a8,
      content: "Easy, fast, and secured unlike other small onlin…",
      amount: 9800,
    },
    {
      name: "GODITO",
      imgs: a9,
      content: "Thanks for OKPeso.I got the money to buy eme… ",
      amount: 8000,
    },
    {
      name: "JOHNPHILIP",
      imgs: a10,
      content: "I am very happy about the credit OKPeso",
      amount: 5800,
    },
    {
      name: "MOVIDA",
      imgs: a11,
      content: "Thanks for OKPeso.I got the money to buy eme… ",
      amount: 9500,
    },
    {
      name: "NAVARRO",
      imgs: a12,
      content: "The process was smooth, and the interest rate is…",
      amount: 8700,
    },
    {
      name: "ROJAS",
      imgs: a13,
      content: "OKPeso helped me when I needed urgent cash!",
      amount: 6500,
    },
    {
      name: "SORIANO",
      imgs: a14,
      content: "Fast approval and easy to use, love the service!",
      amount: 12000,
    },
    {
      name: "VILLANUEVA",
      imgs: a15,
      content: "Hassle-free experience and low interest rates.",
      amount: 10000,
    },
  ];

  const randomItem = () => {
    // return dataSource[Math.floor(Math.random() * dataSource.length)];

    const arr = dataSource;
    const result = new Set();
    const ranNum = 5;

    while (result.size < ranNum) {
      const ran = Math.floor(Math.random() * arr.length);
      result.add(arr[ran]);
    }
    

    sliderInR(document.querySelector("#cardItem-right1"))
    sliderInR(document.querySelector("#cardItem-right2"))
    sliderInL(document.querySelector("#cardItem-left1"))
    sliderInL(document.querySelector("#cardItem-left2"))
    sliderInUp(document.querySelector("#cardItem-centent1"))
    sleep(100).then(() => {
      const resultArr = Array.from(result);
      setLeftCardItem1(resultArr[0]);
      setLeftCardItem2(resultArr[1]);
      setLeftCardItem3(resultArr[2]);
      setLeftCardItem4(resultArr[3]);
      setLeftCardItem5(resultArr[4]);
    });
    
  };

  const [leftCardItem1, setLeftCardItem1] = useState(dataSource[0]);
  const [leftCardItem2, setLeftCardItem2] = useState(dataSource[1]);
  const [leftCardItem3, setLeftCardItem3] = useState(dataSource[2]);
  const [leftCardItem4, setLeftCardItem4] = useState(dataSource[3]);
  const [leftCardItem5, setLeftCardItem5] = useState(dataSource[4]);
  // const [leftCardItem6, setLeftCardItem6] = useState(dataSource[5]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      randomItem();
    }, 6000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);


  const sliderInR = (element) => {
    if (!element) return;
  
    element.animate(
      [
        { opacity: 0, transform: "translateX(20px)" },  
        { opacity: 1, transform: "translateX(-10px)" },  
        { transform: "translateX(0)" },               
      ],
      {
        duration: 1000, 
        easing: "ease", 
      }
    );
  };

  const sliderInL = (element) => {
    if (!element) return;
  
    element.animate(
      [
        { opacity: 0, transform: "translateX(-20px)" },  
        { opacity: 1, transform: "translateX(10px)" },  
        { transform: "translateX(0)" },               
      ],
      {
        duration: 1000, 
        easing: "ease", 
      }
    );
  };
  const sliderInUp = (element) => {
    if (!element) return;
  
    element.animate(
      [
        { opacity: 0, transform: "translate(0,-20px)" },  
        { opacity: 1, transform: "translate(0,10px)" },  
        { transform: "translate(0,0)" },               
      ],
      {
        duration: 1000, 
        easing: "ease", 
      }
    );
  };  

  return (
    <div className="card-item">
      <CardItem
        id="cardItem-left1"
        name={leftCardItem1.name}
        imgs={leftCardItem1.imgs}
        content={leftCardItem1.content}
        amount={leftCardItem1.amount}
        top="3"
        left="0"
      />
      <CardItem
        id="cardItem-right1"
        name={leftCardItem2.name}
        imgs={leftCardItem2.imgs}
        content={leftCardItem2.content}
        amount={leftCardItem2.amount}
        top="8"
        right="3"
      />
      <CardItem
        id="cardItem-centent1"
        name={leftCardItem3.name}
        imgs={leftCardItem3.imgs}
        content={leftCardItem3.content}
        amount={leftCardItem3.amount}
        top="16"
        left="10"
        itemSize="large"
      />
      <CardItem
      id="cardItem-right2"
        
        name={leftCardItem4.name}
        imgs={leftCardItem4.imgs}
        content={leftCardItem4.content}
        amount={leftCardItem4.amount}
        top="29"
        right="5.5"
      />
      <CardItem
        id="cardItem-left2"
        name={leftCardItem5.name}
        imgs={leftCardItem5.imgs}
        content={leftCardItem5.content}
        amount={leftCardItem5.amount}
        top="31"
        left="0"
        itemSize="large"
      />
      {/* <CardItem
        name={leftCardItem6.name}
        imgs={leftCardItem6.imgs}
        content={leftCardItem6.content}
        amount={leftCardItem6.amount}
        top="26"
        right="5.5"
      /> */}
      {/* <div className="left-selection"></div> */}

      {/* right */}

      {/* <div className="right-selection"></div> */}
    </div>
  );
};
