import React from "react"
import "./static/recognized.less"
import imag1 from "../assets/imgs/home/recognized/组 4460@2x.png"
import imag2 from "../assets/imgs/home/recognized/组 4462@2x.png"
import imag3 from "../assets/imgs/home/recognized/组 4463@2x.png"
import imag4 from "../assets/imgs/home/recognized/组 4464@2x.png"



export const Recognized = ({isMobile}) =>{
  
//  const importAllImages = (requireContext) => {
//     return requireContext.keys().map(requireContext);
//   };

  // const images = importAllImages(require.context('../assets/imgs/recognized', false, /\.(png|jpe?g|svg)$/));

  const images = [imag1,imag2,imag3,imag4]
    return (
      <div className={isMobile ? "recognized-mobile" :"recognized"}>
        <div className={"textContainer"}>
          <p className="larg">Every one of your loans is regulated by the following government agencies</p>
          <p className="samll">
            {/* OKPeso is one of the most trustworthy and reliable fintech platforms
            in the Philippines, With OKPeso, just one ID is enough to get the money you need. */}
            SEC Registration : NO.CS201913681
          </p>
        </div>
        <div className={"selection"}>
          {images.map((image, index) => (
            <img
              key={index}
              src={image.default || image}
              alt={`Recognition ${index + 1}`}
              className="selection-image"
            />
          ))}
        </div>
      </div>
    );
}