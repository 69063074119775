import React, { useEffect, useState } from "react";
import "./index.less";

import Input from "./Input";
import Select from "./Select";
import SelectModal from "./SelectModal";
import JobModal from "./JobModal";
import { reportSys } from "@/utils/tools";
const daysLabel = [
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
  "13th",
  "14th",
  "15th",
  "16th",
  "17th",
  "18th",
  "19th",
  "20th",
  "21st",
  "22nd",
  "23rd",
  "24th",
  "25th",
  "26th",
  "27th",
  "28th",
  "29th",
  "30th",
  "31st",
];
const Step3 = (prop) => {
  const { isMobile = true, check = false, stepProp } = prop;
  const { dict = {} } = stepProp;

  const [addObjs, setAddObjs] = useState(null);
  const [showSelect, setShowSelect] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [list, setList] = useState([]);
  const [address, setAddress] = useState([]);
  const [stepObj, setStepObj] = useState({});

  const [step3Obj, setStep3Obj] = useState({
    email: {
      name: "email",
      title: "Email address",
      desc: "Please enter",
    },
    phoneOpt: {
      name: "phoneOpt",
      title: "Alternative phone number",
      desc: "Please enter（Optional）",
    },
    fbUsername: {
      name: "fbUsername",
      title: "Facebook username",
      desc: "Please enter",
    },
    education: {
      name: "education",
      title: "Highest Educational Attainment",
      desc: "Please choose",
    },
    salaryDay: {
      name: "salaryDay",
      title: "Salary Pay Day",
      desc: "Please choose",
    },
  });

  const onChange = (name, value, check) => {
    if (check) {
      stepProp.handleChange && stepProp.handleChange(name, value);
    }
  };
  useEffect(() => {
    reportSys("openRedApplyPage3");
    return () => {};
  }, []);
  const onSelect = (objItem = null, values = null) => {
    if (objItem) {
      let step3ObjTemp = step3Obj;
      if (objItem.name === "salaryDay") {
        if (values) {
          let objTemp = step3ObjTemp[objItem.name];
          objTemp.label =
            values.addrRegion.label + "/" + values.addrProvince.label;
          objTemp.value = values.addrRegion.value;
          setAddObjs(values);
          stepProp.handleJobChange && stepProp.handleJobChange(values);
          setShowAddress(false);
          setStep3Obj(step3ObjTemp);
        } else {
          setShowAddress(false);
        }
      } else {
        let objTemp = step3ObjTemp[objItem.name];
        objTemp.label = values.label;
        objTemp.value = values.value;
        setStep3Obj(step3ObjTemp);
        stepProp.handleSelectChange &&
          stepProp.handleSelectChange(objItem.name, values);
      }
    }
    setShowSelect(false);
  };
  const gotoSelect = (name) => {
    if (name && name === "salaryDay") {
      let addressTemp = [];
      for (let y = 1; y < 32; y++) {
        let monthTemp = [];
        for (let m = 1; m < 32; m++) {
          if (m !== y) {
            monthTemp.push({
              value: m,
              label: daysLabel[m - 1],
              bValue: m < 10 ? "m0" + m : "m" + m,
            });
          }
        }
        addressTemp.push({
          value: y,
          label: daysLabel[y - 1],
          bValue: y,
          children: monthTemp,
        });
      }
      setAddress(addressTemp);
      setShowAddress(true);
    } else if (name && dict[name]) {
      setList(dict[name]);
      setStepObj(step3Obj[name]);
      setShowSelect(true);
    }
  };
  const title = "The current credit is ₱20,000";
  const desc = "The last step. You can withdraw cash immediately.";
  const inputProp = {
    check: stepProp.check,
    onChange,
  };
  const sProp = {
    check: stepProp.check,
    gotoSelect,
  };
  const smProp = {
    onSelect,
    list,
    stepObj,
  };
  const smAddressProp = {
    onSelect,
    address,
    addObjs,
  };
  return (
    <>
      <div className={["step1", !isMobile && "step1Pc"].join(" ")}>
        <div className="s1Title">{title}</div>
        <div className="s1Desc">{desc}</div>
        <Input obj={step3Obj.email} {...inputProp} type="email" />
        <Input
          obj={step3Obj.phoneOpt}
          {...inputProp}
          type="phone"
          inputProp={{ maxLength: 10 }}
        />
        <Input
          obj={step3Obj.fbUsername}
          {...inputProp}
          type="all"
          inputProp={{ maxLength: 30 }}
        />
        <Select stepObj={step3Obj.education} {...sProp} />
        <Select stepObj={step3Obj.salaryDay} {...sProp} />
      </div>

      {showSelect && <SelectModal {...smProp} />}
      {showAddress && <JobModal {...smAddressProp} />}
    </>
  );
};
export default Step3;
