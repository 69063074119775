import React, { useState } from "react";
import serviceCall from "@/assets/imgs/serviceCall.png";
import "./server.less";
import serviceFb from "@/assets/imgs/serviceFb.png";
import {
  reportContactGotoFB,
  reportContactSmartPhone,
  reportContactGlobePhone,
  // reportGotoFB,
  // reportSmartPhone,
  // reportGlobePhone
} from "../../utils/tools";

const ServiceModal = (prop) => {
  const [callModal, setCallModal] = useState(false);
  const [formattedPhone, setFormattedPhone] = useState({});
  const title1 = "CONTACT US";
  const title2 = "9:00 AM - 6:00 PM";
  const smartPhone = "09319533595";
  const globePhone = "09052553180";
  const fbUrl = "https://www.facebook.com/OKPesoHub";
  const smart = "Smart:  " + smartPhone;
  const Globe = "Globe: " + globePhone;
  const fbOnline = "Online consultation";
  const end = "Cancel";
  const smartType = "smart";
  const globeType = "globe";
  const handleService = (phone,type) => {
    reportService(type);
    let formattedPhone = phone.replace(/(\d{4})(\d{4})(\d{3})/, "$1 - $2 - $3");
    setFormattedPhone({
      phone,
      phoneDesc: formattedPhone,
    });
    setCallModal(true);
  };
  const handleFb = () => {
    reportContactGotoFB()
    window.open(fbUrl, "_blank");
  };

  const reportService = (type) =>{
    if(type === smartType){
      reportContactSmartPhone()
      return
    }
    if(type === globeType){
      reportContactGlobePhone()
      return
    }
  }
  const renderCall = () => {
    return (
      <div className={"callModal"}>
        <div className={"testMask"} />
        <div className={"content"}>
          {
            <div className={"infoModal"}>
              <img src={serviceCall} className="serviceCall" alt="" />
              <div className="formattedPhone">{formattedPhone.phoneDesc}</div>
              <a href={`tel:${formattedPhone.phone}`} className="call">
                CALL
              </a>
              <div
                className="cancel"
                onClick={() => {
                  setCallModal(false);
                  prop.handleOpenService && prop.handleOpenService(false);
                }}
              >
                Cancel
              </div>
            </div>
          }
        </div>
      </div>
    );
  };
  return (
    <>
      {!callModal && (
        <div className={"serviceModal"}>
          <div className={"contentServer"}>
            <div className={"infoModal"}>
              <div className="title1">{title1}</div>
              <div className="title2">{title2}</div>
              <div className="smart" onClick={() => handleFb()}>
                <span className="facebook">
                  <img src={serviceFb} className={"serviceFb"} alt="" />
                  {fbOnline}
                </span>
                <svg className="next">
                  <use xlinkHref="#iconnext" />
                </svg>
              </div>
              <div
                className="smart"
                onClick={() => handleService(smartPhone,smartType)}
                style={{ borderTop: 0 }}
              >
                <span>{smart}</span>
                <svg className="next">
                  <use xlinkHref="#iconnext" />
                </svg>
              </div>
              <div
                className="smart"
                onClick={() => handleService("09052553180",globeType)}
                style={{ borderTop: 0 }}
              >
                <span>{Globe}</span>
                <svg className="next">
                  <use xlinkHref="#iconnext" />
                </svg>
              </div>
              <div
                className="Cancel"
                onClick={() => {
                  prop.handleOpenService && prop.handleOpenService(false);
                }}
              >
                {end}
              </div>
            </div>
          </div>
        </div>
      )}
      {callModal && renderCall()}
    </>
  );
};

export default ServiceModal;
