import React, { useState } from "react";
import styles from "./index.less";
import b21 from "@/assets/landing/v1/b21@2x.png";
import b22 from "@/assets/landing/v1/b22@2x.png";
import b23 from "@/assets/landing/v1/b23@2x.png";
import b24 from "@/assets/landing/v1/b24@2x.png";

const Block2 = (prop) => {
  const title =
    "Every one of your loans is regulated by the following government agencies.";
  const desc1 = "SEC Registration: NO.CS201913681";
  const desc2 = "Certificate Of Authority: NO.3059";
  const list = [{ img: b21 }, { img: b22 }, { img: b23 }, { img: b24 }];
  const { isMobile = true } = prop;
  return (
    <div className={["block2", !isMobile && "block2Pc"].join(" ")}>
      <div className={"title2"}>{title}</div>
      <div className={"desc2"}>{desc1}</div>
      <div className={"desc2"}>{desc2}</div>
      <div className={"content2"}>
        {list.map((item) => (
          <img src={item.img} />
        ))}
      </div>
    </div>
  );
};
export default Block2;
