import React, { useState } from "react";
import "./index.less";
import selectClose from "@/assets/landing/selectClose.png";

const SelectModal = (prop) => {
  const { isMobile = true, stepObj, list = [], value = null, onSelect } = prop;

  return (
    <div className={["aSelectModal", !isMobile && "aSelectModalPc"].join(" ")}>
      <div className={"asmInfo"}>
        <div className={["asmHeader", "asmHeaderBase"].join(" ")}>
          <div className={"asmTxt"}>{stepObj.title}</div>
          <img onClick={() => onSelect()} src={selectClose} />
        </div>
        {/* {
          stepObj.label ?  <div className={"asmLine"}>
          <div
            className={["asmsLabel", stepObj.label && "asmsLabelOn"].join(" ")}
          >
            {stepObj.label}
          </div>  
        </div>
        }
        */}
        <div className={"asmList"}>
          {list.map((item) => (
            <div
              className={[
                "asmItem",
                stepObj.value && stepObj.value === item.value && "asmItemOn",
              ].join(" ")}
              onClick={() => onSelect(stepObj, item)}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default SelectModal;
