import React, { useEffect, useRef, useState } from "react";
import "./index.less";
import successIcon from "@/assets/landing/success.png";
import errorIcon from "@/assets/landing/error.png";
import testAmountLoading from "@/assets/imgs/testAmountLoading.gif";

const Message = (prop) => {
  useEffect(() => {}, []);
  const { infoObj = { txt: "", type: "success", icon: null } } = prop;
  return (
    <div className={"sysMsg"}>
      <div className={"sysInfo"}>
        {infoObj.type === "loading" ? (
          <img src={testAmountLoading} />
        ) : (
          <>
            {infoObj.type !== "wait" && (
              <img src={infoObj.type === "success" ? successIcon : errorIcon} />
            )}
            <div className={"sysInfoTxt"}>
              {infoObj.icon && <img src={infoObj.icon} />}
              {infoObj.txt}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default Message;
