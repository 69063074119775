import React from 'react';
import { enquireScreen } from 'enquire-js';

import './pushInfo.less';
import { message } from 'antd';


let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});


class ServiceAgreement extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
    pushInfo: []
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
    const {location: {search=undefined}} = this.props
    console.log(this.props.location.search);
    
    if(search && search.indexOf('alertName') > -1){
        fetch(window.location.origin +`/third/alertInfo${search}`,{
            method:'GET',
          headers:{'Content-Type':'application/json;charset=UTF-8'},
          mode:'no-cors',
          cache:'default'
        })
        .then(res =>res.json())
        .then((res) => {
          if(res && res.ret === 0) {
            const { data=[] } = res;
            if(!data[0].isQc) {
              const groupedData = data.reduce((accumulator, item) => {  
                // 将 userGroup 转换为数字，作为 group 的值  
                const group = item.userGroup;  
                  
                // 检查当前组是否已存在  
                const groupIndex = accumulator.findIndex(grp => grp.group === group);  
                if (groupIndex !== -1) {  
                    // 如果存在，将当前项添加到该组的 groupList 中  
                    accumulator[groupIndex].groupList.push(item);  
                } else {  
                    // 如果不存在，创建一个新组并添加到累加器中  
                    accumulator.push({ group, groupList: [item], ...item });  
                }  
                return accumulator;  
            }, []); // 初始值是一个空数组  
              this.setState({
                  pushInfo: groupedData
              })
            } else {
              this.setState({
                  pushInfo: data
              })
            }
          }
        }) 
    }


  }

  handleClose = (type, time, username, job, service, personName) => {
    //type 非qc： 2组close闭  1个人close闭   qc：3 个人close闭  Allclose闭
    let fetchUrl = window.location.origin +'/third/collAlarmSliences?'
    let types = type
    if(type === 4) type = 3
    fetchUrl = `${fetchUrl}type=${type}&time=${time}`
    if((types === 2 || types === 1) && username){
        fetchUrl = fetchUrl + `&userGroup=${encodeURI(username)}`
    }

    if((types === 4 || types === 3) && username){
        fetchUrl = fetchUrl + `&alertname=${username}`
    }

    if(job) fetchUrl = fetchUrl + `&job=${job}`
    if(service) fetchUrl = fetchUrl + `&service=${service}`

    if(types === 1 && personName){
        fetchUrl = fetchUrl + `&collId=${personName}`
    }

    if(types === 3 && personName){
        fetchUrl = fetchUrl + `&userName=${personName}`
    }


    fetch(fetchUrl,{
        method:'GET',
      headers:{'Content-Type':'application/json;charset=UTF-8'},
      mode:'no-cors',
      cache:'default'
    })
    .then(() => {
        message.success('close success')
    }) 
  }



  render() {
    const {pushInfo} = this.state
    console.log(pushInfo,'pushInfo');
    return (
      <div className={'contentWrapper'}>
        {
            pushInfo.map((item,index)=> {
                console.log(item,'tt');
                return <div>
                    {item.isQc ? null : <div><span className={'red'}>Group:</span> {item.userGroup} 
                    <span className='close' onClick={()=>this.handleClose(2, 40, item.userGroup, item.job, item.service)}>【close 40m】</span>
                    <span className='close' onClick={()=>this.handleClose(2, 120, item.userGroup, item.job, item.service)}>【close 2h】</span>
                    <span className='close' onClick={()=>this.handleClose(2, 240, item.userGroup, item.job, item.service)}>【close 4h】</span>
                    <span className='close' onClick={()=>this.handleClose(2, 720, item.userGroup, item.job, item.service)}>【close 1d】</span>
                    </div>}
                    {item.isQc ? null : <div><span className={'red'}>Group leader:</span> {item.groupLeader}</div>}
                    {item.isQc ? <div>{'{'}<span className={'yellow'}>{item.userName}</span>{'}'}:  no work record,start time:{item.showStartsAt}
                        <span className='close' onClick={()=>this.handleClose(item.isQc ? 3 : 1, 40, item.isQc ? item.alertname : item.userGroup, item.job, item.service, item.isQc ? item.userName : item.collId)}>【close 40m】</span>
                        <span className='close' onClick={()=>this.handleClose(item.isQc ? 3 : 1, 120, item.isQc ? item.alertname : item.userGroup, item.job, item.service, item.isQc ? item.userName : item.collId)}>【close 2h】</span>
                        <span className='close' onClick={()=>this.handleClose(item.isQc ? 3 : 1, 240, item.isQc ? item.alertname : item.userGroup, item.job, item.service, item.isQc ? item.userName : item.collId)}>【close 4h】</span>
                        <span className='close' onClick={()=>this.handleClose(item.isQc ? 3 : 1, 720, item.isQc ? item.alertname : item.userGroup, item.job, item.service, item.isQc ? item.userName : item.collId)}>【close 1d】</span>
                    </div> : 
                      <div>
                        {
                          item.groupList.map(u=> {
                            return <div key={u.group}>
                              <div>{'{'}<span className={'yellow'}>{u.userName}</span>{'}'}:  no work record,start time:{u.showStartsAt}
                                  <span className='close' onClick={()=>this.handleClose(u.isQc ? 3 : 1, 40, u.isQc ? u.alertname : u.userGroup, u.job, u.service, u.isQc ? u.userName : u.collId)}>【close 40m】</span>
                                  <span className='close' onClick={()=>this.handleClose(u.isQc ? 3 : 1, 120, u.isQc ? u.alertname : u.userGroup, u.job, u.service, u.isQc ? u.userName : u.collId)}>【close 2h】</span>
                                  <span className='close' onClick={()=>this.handleClose(u.isQc ? 3 : 1, 240, u.isQc ? u.alertname : u.userGroup, u.job, u.service, u.isQc ? u.userName : u.collId)}>【close 4h】</span>
                                  <span className='close' onClick={()=>this.handleClose(u.isQc ? 3 : 1, 720, u.isQc ? u.alertname : u.userGroup, u.job, u.service, u.isQc ? u.userName : u.collId)}>【close 1d】</span>
                              </div>
                              {!item.isQc && item.status === 'resolved' && <div>{'{'}<span className={'greenyellow'}>{item.userName}</span>{'}'}:  return to work,start time:{u.showTimeResolve}</div>}
                            </div>
                          })
                        }

                      </div>
                    }
                    {item.isQc && item.status === 'resolved' && <div>{'{'}<span className={'greenyellow'}>{item.userName}</span>{'}'}:  return to work,start time:{item.showTimeResolve}</div>}
                    {index !== pushInfo.length - 1 && <hr className='line'/>}
                    {index === pushInfo.length -1 && pushInfo[0].isQc && <>
                        <hr className='line'/>
                        <div>All: 
                        <span className='close' onClick={()=>this.handleClose(4, 40, item.alertname, item.job, item.service)}>【close 40m】</span>
                        <span className='close' onClick={()=>this.handleClose(4, 120, item.alertname, item.job, item.service)}>【close 2h】</span>
                        <span className='close' onClick={()=>this.handleClose(4, 240, item.alertname, item.job, item.service)}>【close 4h】</span>
                        <span className='close' onClick={()=>this.handleClose(4, 720, item.alertname, item.job, item.service)}>【close 1d】</span></div>
                    </>}
                </div>
            })
        }
      </div>
    );
  }
}
export default ServiceAgreement;
