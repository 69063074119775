import React, { PureComponent } from "react";

import testAmountLogo from "../../assets/imgs/pro/v1/logo.png";
import b2 from "../../assets/imgs/pro/v1/b2@2x.png";
import b21 from "../../assets/imgs/pro/v1/b21@2x.png";
import b22 from "../../assets/imgs/pro/v1/b22@2x.png";
import b23 from "../../assets/imgs/pro/v1/b23@2x.png";
import b31 from "../../assets/imgs/pro/v1/b31@2x.png";
import b32 from "../../assets/imgs/pro/v1/b32@2x.png";
import b33 from "../../assets/imgs/pro/v1/b33@2x.png";
import b34 from "../../assets/imgs/pro/v1/b34@2x.png";
import b41 from "../../assets/imgs/pro/v1/b41@2x.png";
import b42 from "../../assets/imgs/pro/v1/b42@2x.png";
import b43 from "../../assets/imgs/pro/v1/b43@2x.png";
import "./proV1.less";
import { txt1, txt2, txt3, txt4, txt5 } from "./imgs.js";
import { enquireScreen } from "enquire-js";
import { reportDownload, reportPage } from "../../utils/tools";
let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

export class TestAmount extends PureComponent {
  state = {
    repayRef: React.createRef(),

    downLoanUrl: null,

    isMobile,
    servicePhone: "+639052553180",
  };

  props = this.props;
  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    console.log(window);
    const { fbq } = window;
    if (typeof fbq === "function") {
      fbq("trackCustom", "ProV1Page", { eventTag: "adtiger" });
    }
    reportPage();
    fetch(window.location.origin + `/api/limit/test/page?a=1&k=12`, {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=UTF-8" },
      mode: "no-cors",
      cache: "default",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.ret === 0) {
          this.setState({
            downLoanUrl: res.data.downloadUrl,
            // servicePhone: res.data.phone,
          });
        }
      });
  }

  handleDownload = () => {
    const { downLoanUrl } = this.state;
    reportDownload();
    if (downLoanUrl) {
      window.open(downLoanUrl);
    }
  };

  render() {
    const { isMobile, servicePhone } = this.state;
    // const txt1 = {
    //   t1: "Instant approval in",
    //   t2: "Get the cash you need now!",
    //   t3: "High loan amount",
    //   t4: "30,000",
    //   t5: "1% Low Interest",
    //   t6: "Borrow Now",
    //   t7: "Enjoy flexible repayment terms!",
    // };
    // const txt2 = {
    //   btn: "Easy Steps",
    //   t1: "Install App",
    //   t2: "Get Approved",
    //   t3: "Receive Cash",
    // };
    // const txt3 = {
    //   btn: "Advantage",
    //   t1: "Fast Approval",
    //   t11: "Get quickly",
    //   t2: "No hidden fees",
    //   t22: "Easy loans",
    //   t3: "Simple Process",
    //   t33: "No collateral",
    //   t4: "Loan Discounts",
    //   t44: "New users",
    // };
    // const txt4 = {
    //   btn: "OKPeso Introduction",
    //   t1: "3 Million Users",
    //   t11: "Have successfully made loans",
    //   t2: "Safe&Trusted",
    //   t22: "Tried and tested by many Filipinos.",
    //   t3: "SEC Information",
    //   t33: "Certificate of Authorization: NO.3059\nCodeblock Lending Inc.\nNo.CS201913681",
    // };
    return (
      <div className={["normal", !isMobile && "normalPc"].join(" ")}>
        <div className={"bigBlock1"}>
          <div className={"navs"}>
            <img className={"logo"} src={testAmountLogo} alt="testAmountLogo" />
          </div>
          <div className={"block1"}>
            <div className={"t11"}>
              <img src={txt1.t11} alt="t11" />
            </div>
            <div className={"item2"}>
              <img src={txt1.t12} className={"t12"} alt="t12" />
            </div>
            <div className={"item3"}>
              <div className={"tipInfo"}>
                <img className={"tip4"} src={txt1.amount} alt="t13" />
                <img src={txt1.t14} className={"t14"} alt="t14" />
                <div onClick={() => this.handleDownload()}>
                  <img src={txt1.t15} className={"t15"} alt="t15" />
                </div>
              </div>

              <img src={txt1.t13} className={"t13"} alt="t13" />
            </div>
          </div>
          <div className={"block2"}>
            <div className={"b2"}>
              <img src={txt2.t2} className={"t2"} alt="t2" />
            </div>
            <div>
              <img src={b21} className={"b2Img"} alt="b2Img" />
              <div className={"b2Text"}>
                <img src={txt2.t21} className={"t21"} alt="t21" />
              </div>
            </div>
            <div>
              <div className={"b2Img"}>
                <img src={b2} className={"b2Icon"} alt="b2Icon" />
              </div>
            </div>
            <div>
              <img src={b22} className={"b2Img"} alt="b2Img" />
              <div className={"b2Text"}>
                <img src={txt2.t22} className={"t21"} alt="t22" />
              </div>
            </div>
            <div>
              <div className={"b2Img"}>
                <img src={b2} className={"b2Icon"} alt="b2Icon" />
              </div>
            </div>
            <div>
              <img src={b23} className={"b2Img"} alt="b2Img" />
              <div className={"b2Text"}>
                <img src={txt2.t23} className={"t23"} alt="t23" />
              </div>
            </div>
          </div>
        </div>

        <div className={"bigBlock2"}>
          <div className={"block3"}>
            <div className={"b3"}>
              <div>
                <img src={txt3.t3} className={"t3"} alt="t3" />
              </div>
            </div>
            <div className="item">
              <img className="icon" src={b31} alt="b31" />
              <div className="title">
                <img src={txt3.t31} className={"t31"} alt="t31" />
              </div>
              <div className="desc">
                <img src={txt3.d31} className={"d31"} alt="d31" />
              </div>
            </div>
            <div className="item">
              <img className="icon" src={b32} alt="b32" />
              <div className="title">
                <img src={txt3.t32} className={"t31"} alt="t32" />
              </div>
              <div className="desc">
                <img src={txt3.d32} className={"d31"} alt="d32" />
              </div>
            </div>
            <div className="item">
              <img className="icon" src={b33} alt="b33" />
              <div className="title">
                <img src={txt3.t33} className={"t31"} alt="t33" />
              </div>
              <div className="desc">
                <img src={txt3.d33} className={"d31"} alt="d33" />
              </div>
            </div>
            <div className="item">
              <img className="icon" src={b34} alt="b34" />
              <div className="title">
                <img src={txt3.t34} className={"t31"} alt="t34" />
              </div>
              <div className="desc">
                <img src={txt3.d34} className={"d31"} alt="d34" />
              </div>
            </div>
          </div>
          <div className={"block4"}>
            <div className={"b4"}>
              {" "}
              <img src={txt4.t4} className={"t4"} alt="t4" />
            </div>
            <div className="item">
              <img className="icon" src={b41} alt="b41" />
              <div className="title">
                <img src={txt4.t41} className={"t41"} alt="t41" />
              </div>
              <div className="desc">
                <img src={txt4.d41} className={"d41"} alt="d41" />
              </div>
            </div>
            <div className="item">
              <img className="icon" src={b42} alt="b42" />
              <div className="title">
                <img src={txt4.t42} className={"t41"} alt="t42" />
              </div>
              <div className="desc">
                <img src={txt4.d42} className={"d41"} alt="d42" />
              </div>
            </div>
            <div className="item">
              <img className="icon" src={b43} alt="b43" />
              <div className="title">
                <img src={txt4.t43} className={"t41"} alt="t43" />
              </div>
              <div className="desc">
                <img src={txt4.d43} className={"d43"} alt="d43" />
              </div>
            </div>
          </div>
          <div className={"block5"}>
            <div>
              <img src={txt5.t51} className={"t51"} alt="t51" />
            </div>
            <div>
              <img src={txt5.t52} className={"t52"} alt="t52" />
            </div>
            <div className="b5End">
              <img src={txt5.t53} className={"t53"} alt="t53" />
            </div>
            <a className="b5Call" href={"tel:" + servicePhone}>
              <img src={txt5.b51} className={"b51"} alt="b51" />
              {servicePhone}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default TestAmount;
