import React, { useState } from "react";
import styles from "./index.less";
import s1 from "@/assets/landing/v1/s1@2x.png";
import s2 from "@/assets/landing/v1/s2@2x.png";
import s3 from "@/assets/landing/v1/s3@2x.png";
import s4 from "@/assets/landing/v1/s4@2x.png";

const Block1 = (prop) => {
  const title = "OKPeso";
  const features = ["Safe", "Fast", "Convenient"];
  const list = [
    { title: "Quick approval", desc: "3 minutes", img: s1 },
    { title: "Only an ID", desc: "card’s needed", img: s2 },
    { title: "0.06%", desc: "interest/day", img: s3 },
    { title: "Up to", desc: "180 days", img: s4 },
  ];
  const { isMobile = true } = prop;
  return (
    <div className={["block1", !isMobile && "block1Pc"].join(" ")}>
      <div className={"title1"}>{title}</div>
      <div className={"desc1"}>
        {features.map((item) => (
          <div>{item}</div>
        ))}
      </div>
      <div className={"content1"}>
        {list.map((item) => (
          <div className={"item"}>
            <img src={item.img} />
            <div>
              <div>{item.title}</div>
              <div>{item.desc}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Block1;
