import {reportDownload} from "./tools"
export const homeJumpToSection = (id) => {
    console.log('jumpToSection',id)
    if(id === 'FAQ'){
      window.location.href = '/faq'
        // history.push('/faq');  // 用来导航到 FAQ 页面
    }
    if(id === 'Home'){
      window.location.href = '/'
    }
    if(id === 'PrivacyPolicy'){
      window.location.href = '/privacyAgreement'
    }
    if(id === 'Download'){
      downloadClick();
    }
  };

export const getDownloadUrl = async () => {
  var downLoanUrl = "";
  let downloadK = 1
  if(window.location.href.indexOf('from=search') > -1){
    downloadK = 20
  }
  await fetch(
    window.location.origin + `/api/download/page/config?hash=1&k=${downloadK}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=UTF-8" },
      mode: "no-cors",
      cache: "default",
    }
  )
    .then((res) => res.json())
    .then((res) => {
      if (res && res.ret === 0) {
        const { data = {} } = res;
        downLoanUrl =data.url
        console.log("setAndro", data.url);
      }
    });
  return downLoanUrl;
};


  export const getDownloadUrlBySession = async() =>{
    var url = sessionStorage.getItem('downloadApkUrl');
    console.log('getDownloadUrlBySession url')
    if(!url){
      url = await getDownloadUrl();
      console.log('getDownloadUrlBySession  url is null get url',url)
      if(url !== ""){
        console.log('settItem',url)
        sessionStorage.setItem('downloadApkUrl',url)
      }
    }
    return url;
  }

    
  export const downloadClick = async() => {
    const downloadUrl = await getDownloadUrlBySession();
    console.log("downloadClick downloadUrl:",downloadUrl)
    if (downloadUrl) {
      reportDownload();
      console.log("downloading:",downloadUrl)
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "";
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Download URL is not available");
    }
  };


 export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
