import React, { useState } from "react";
import "./index.less";
import phLogo from "@/assets/landing/phLogo.png";
import del from "@/assets/landing/del.png";

const RegPhone = (prop) => {
  const [phone, setPhone] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);

  const contryCode = "+63";
  const phonePre = "9*********";
  const regNum = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 0, 100];
  const regTitle = "Log in to claim the reward";
  const errorInfo = "10 digits starting with 9";
  const addPhone = (e, item) => {
    if (item === 10) {
      return;
    }
    // e.preventDefault();
    if (item === 100) {
      if (phone) {
        if (phone.length === 1) {
          setPhone("");
        } else {
          let newPhone = String(phone).substring(0, phone.length - 1);
          setPhone(newPhone);
        }
      }
    } else {
      if (!phone || phone.length < 10) {
        let newPhone = phone + "" + item;
        console.log("newPhone", newPhone);
        setPhone(newPhone);
        const phonePattern = /^(9[0-9])\d{8}$/;
        if (phonePattern.test(newPhone) && newPhone.length === 10) {
          setErrorMsg(null);
          prop.handleReg && prop.handleReg(newPhone);
        } else {
          setErrorMsg(errorInfo);
        }
      }
    }
  };

  return (
    <div className={"regPhone"}>
      <div className={"regInfo"}>
        <div className={"regTop"}>
          <div className={"regTitle"}>{regTitle}</div>
          <div className={"rtPhone"}>
            <img src={phLogo} />
            <div className={"rtCode"}>{contryCode}</div>
            <div className={!!phone ? "rtValue" : "rtInput"}>
              {phone || phonePre}
            </div>
          </div>
          {errorMsg && <div className={"rtError"}>{errorMsg}</div>}
        </div>
        <div className={"regNum"}>
          {regNum.map((item) => (
            <div
              className={item > 9 ? "regItemDel" : "regItem"}
              key={item}
              onClick={(e) => addPhone(e, item)}
            >
              {item > 9 ? (
                item === 100 ? (
                  <img src={del} />
                ) : (
                  <div className={"regNone"} />
                )
              ) : (
                <div>{item}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default RegPhone;
