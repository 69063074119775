import React from "react";
import ReactDOM from "react-dom";
import "./base.css";
import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA


if ('serviceWorker' in navigator) {

  
  window.addEventListener('load', () => {
    
    // // 检查是否已经重定向过，防止无限循环  
    // if (sessionStorage.getItem('redirected')) {  
    //   // 如果已经重定向过，则不执行任何操作  
    //   return;  
    // }  
  
    // if (window.matchMedia('(display-mode: standalone)').matches) {  
    //   // 设置重定向标志  
    //   sessionStorage.setItem('redirected', 'true');  
    //   // 重定向到 /landingHome  
    //   window.location.href = '/landingHome';  
    // } else {  
    //   // 如果没有重定向，但后续可能需要清除标志（例如，用户从浏览器直接访问页面）  
    //   // 这里可以选择性地清除标志，但在这个例子中我们不需要  
    //   // sessionStorage.removeItem('redirected');  
    // } 
    
    navigator.serviceWorker.register('./serviceWorker.js')
      .then(registration => {
        console.log('ServiceWorker registration successful with scope: ', registration.scope);

        
      })
      .catch(error => {
        console.error('ServiceWorker registration failed: ', error);
      });
  });
}

(function flexible(window, document) {
  var docEl = document.documentElement;
  var dpr = window.devicePixelRatio || 1;

  // adjust body font size
  function setBodyFontSize() {
    if (document.body) {
      document.body.style.fontSize = "32px";
    } else {
      /*
          DOMContentLoaded DOM元素加载完成就触发
          load 页面所有资源加载触发
        */
      document.addEventListener("DOMContentLoaded", setBodyFontSize);
    }
  }
  setBodyFontSize();

  // set 1rem = viewWidth / 100
  function setRemUnit() {
    var rem = docEl.clientWidth / 100;
    docEl.style.fontSize = rem + "px";
  }

  setRemUnit();

  // reset rem unit on page resize
  window.addEventListener("resize", setRemUnit);
  window.addEventListener("pageshow", function (e) {
    if (e.persisted) {
      setRemUnit();
    }
  });

  // detect 0.5px supports
  if (dpr >= 2) {
    var fakeBody = document.createElement("body");
    var testElement = document.createElement("div");
    testElement.style.border = ".5px solid transparent";
    fakeBody.appendChild(testElement);
    docEl.appendChild(fakeBody);
    if (testElement.offsetHeight === 1) {
      docEl.classList.add("hairlines");
    }
    docEl.removeChild(fakeBody);
  }
})(window, document);
