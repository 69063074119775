import t11 from "../../assets/imgs/pro/v1/t11@2x.png";
import t12 from "../../assets/imgs/pro/v1/t12@2x.png";
import t13 from "../../assets/imgs/pro/v1/t13@2x.png";
import t14 from "../../assets/imgs/pro/v1/t14@2x.png";
import t15 from "../../assets/imgs/pro/v1/t15@2x.png";
import amount from "../../assets/imgs/pro/v1/amount.gif";
import t2 from "../../assets/imgs/pro/v1/t2@2x.png";
import t21 from "../../assets/imgs/pro/v1/t21@2x.png";
import t22 from "../../assets/imgs/pro/v1/t22@2x.png";
import t23 from "../../assets/imgs/pro/v1/t23@2x.png";
import t3 from "../../assets/imgs/pro/v1/t3@2x.png";
import t31 from "../../assets/imgs/pro/v1/t31@2x.png";
import t32 from "../../assets/imgs/pro/v1/t32@2x.png";
import t33 from "../../assets/imgs/pro/v1/t33@2x.png";
import t34 from "../../assets/imgs/pro/v1/t34@2x.png";
import t4 from "../../assets/imgs/pro/v1/t4@2x.png";
import t41 from "../../assets/imgs/pro/v1/t41@2x.png";
import t42 from "../../assets/imgs/pro/v1/t42@2x.png";
import t43 from "../../assets/imgs/pro/v1/t43@2x.png";
import t51 from "../../assets/imgs/pro/v1/t51@2x.png";
import t52 from "../../assets/imgs/pro/v1/t52@2x.png";
import t53 from "../../assets/imgs/pro/v1/t53@2x.png";
import b51 from "../../assets/imgs/pro/v1/b51@2x.png";

import d31 from "../../assets/imgs/pro/v1/d31@2x.png";
import d32 from "../../assets/imgs/pro/v1/d32@2x.png";
import d33 from "../../assets/imgs/pro/v1/d33@2x.png";
import d34 from "../../assets/imgs/pro/v1/d34@2x.png";
import d41 from "../../assets/imgs/pro/v1/d41@2x.png";
import d42 from "../../assets/imgs/pro/v1/d42@2x.png";
import d43 from "../../assets/imgs/pro/v1/d43@2x.png";
export const txt1 = {
  t11,
  t12,
  t13,
  amount,
  t14,
  t15,
};
export const txt2 = {
  t2,
  t21,
  t22,
  t23,
};
export const txt3 = {
  t3,
  t31,
  t32,
  t33,
  t34,
  d31,
  d32,
  d33,
  d34,
};
export const txt4 = {
  t4,
  t41,
  t42,
  t43,
  d41,
  d42,
  d43,
};
export const txt5 = {
  t51,
  t52,
  t53,
  b51,
};
