import React, { useState } from "react";
import "./index.less";
import more from "@/assets/landing/more@2x.png";
import time from "@/assets/landing/time.png";
import RegPhone from "./RegPhone";
import { reportSys } from "@/utils/tools";
import Message from "../Message";
import { canGoMarket } from "@/utils/tools";
import { reportDownload } from "../../../utils/tools";
import UserAgentConponents from "../../UserAgentConponent";
const Download = (prop) => {
  const [showPhone, setShowPhone] = useState(false);
  const [msgObj, setMsgObj] = useState({ txt: "", type: "loading" });
  const [showMsg, setShowMsg] = useState(false);
  const [userAgentVisible, setUserAgentVisible] = useState(false);
  const title1 = "END WITHIN";
  const title2 = prop.type && prop.type === "ss" ? "Apply Now" : "Install APP";
  const desc2 = "Get an additional ₱5000";

  const {
    isMobile = true,
    countObj = { minute: "10", second: "00" },
    showCount = true,
  } = prop;
  const gotoCheck = () => {
    reportSys("installAPK-click");
    reportSys("openEnterPhone");
    setShowPhone(true);
  };
  const handleReg = (phone) => {
    setShowMsg(true);
    setShowPhone(false);
    fetch(window.location.origin + `/api/limit/test/sms`, {
      method: "POST",
      headers: { "Content-Type": "application/json;charset=UTF-8" },
      cache: "default",
      body: JSON.stringify({
        appId: 1,
        phone,
        sendSms: 0,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.ret === 0) {
          const { isRegister } = res.data;
          localStorage.setItem(
            "isRegister",
            isRegister === "1" ? "OldUser" : "NewUser"
          );

          // let count = 3;
          // let nSInv = setInterval(() => {
          //   count--;
          //   setMsgObj({ txt: count + "s", type: "wait" });
          //   if (count <= 0) {
          //     setShowMsg(false);
          //     clearInterval(nSInv);
          //     setTimeout(() => {
          //       let deviceType = canGoMarket();

          //       if (isRegister === "1") {
          //         //老户
          //         if (deviceType) {
          //           setUserAgentVisible(true);
          //         } else {
          //           //旧逻辑
          //           reportDownload();
          //           setMsgObj({ txt: "success", type: "success" });
          //           // window.location.href = prop.downLoanUrl;
          //           window.open(prop.downLoanUrl, "_blank");
          //         }
          //       } else {
          //         // 新户走授信
          //         localStorage.setItem("submitPhone", phone);
          //         prop.nextPart && prop.nextPart();
          //       }
          //     }, 1000);
          //   }
          // }, 1000);

          setShowMsg(false);
          // clearInterval(nSInv);

          setTimeout(() => {
            if (isRegister === "1") {
              prop.handleEnd && prop.handleEnd();
            } else {
              // 新户走授信
              localStorage.setItem("submitPhone", phone);
              prop.nextPart && prop.nextPart();
            }
          }, 1000);
        } else {
          setMsgObj({ txt: res.msg, type: "error" });
          setTimeout(() => {
            setShowMsg(false);
          }, 2000);
        }
      });
  };
  let UserAgentConponentProps = {
    downLoanApkUrl: prop.downLoanUrl,
  };
  return (
    <>
      <div
        className={["downloadApply", !isMobile && "downloadApplyPc"].join(" ")}
        onClick={() => gotoCheck()}
      >
        {showCount && (
          <div className={"left"}>
            <div>{title1}</div>
            <div className={"time"}>
              <span>{countObj.minute}</span>
              <img src={time} />
              <span>{countObj.second}</span>
            </div>
          </div>
        )}

        <div className={"center"}>
          <div>{title2}</div>
          <div className={"cDesc"}>{desc2}</div>
        </div>
        <img className={"right"} src={more} />
      </div>
      {showPhone && <RegPhone handleReg={handleReg} />}
      {showMsg && <Message infoObj={msgObj} />}
      {userAgentVisible && <UserAgentConponents {...UserAgentConponentProps} />}
    </>
  );
};
export default Download;
