import React from "react";
import {
  getDeviceType,
  reportDownload,
  reportDownloadApk,
  reportGoAppStore,
} from "../utils/tools";
import "./userAgentConponent.less";

class UserAgentConponents extends React.PureComponent {
  state = {
    downloadModalVisible: false,
  };

  componentDidMount() {
    this.redirectToAppMarket();
  }

  componentWillUnmount() {
    this.setState({
      downloadModalVisible: false,
    });
  }

  redirectToAppMarket() {
    let deviceType = getDeviceType();
    const { downLoanApkUrl } = this.props;

    let appPackageName = "tech.codeblock.okpeso"; //app包名
    let appMarketUrl = appPackageName;
    switch (deviceType) {
      case "Huawei":
        appMarketUrl = "appmarket://details?id=" + appMarketUrl;
        break;

      case "OPPO":
        appMarketUrl = "oppomarket://details?packagename=" + appMarketUrl;
        break;
      case "Vivo":
        appMarketUrl = "vivomarket://details?id=" + appMarketUrl;
        break;
      // case 'Xiaomi':
      //     appMarketUrl = 'mimarket://details?id=' + appMarketUrl;
      //     break;
      // case 'Samsung':
      //     appMarketUrl = 'samsungapps://ProductDetail/' + appMarketUrl;
      //     break;
      default:
        break;
    }

    if (deviceType) {
      //弹提示框 去应用商城
      reportGoAppStore();
      this.setState({ downloadModalVisible: true });
      window.location.href = appMarketUrl;
    } else {
      reportDownload();
      //直接下载apk
      window.location.href = downLoanApkUrl;
    }
  }

  handleDownloadApk = () => {
    const { downLoanApkUrl } = this.props;
    window.location.href = downLoanApkUrl;
    reportDownloadApk();
    // 点击下载按钮 隐藏弹窗
    this.setState({ downloadModalVisible: false });
  };

  render() {
    const { downloadModalVisible } = this.state;
    return downloadModalVisible ? ( //走应用商城
      <div className={"userAgentModal"}>
        <div className={"userAgentMask"} />
        <div className={"content"}>
          <div className={"infoModal"}>
            <div className={"downloadDesc"}>
              Please install OKPeso in the app store, if you can't download and
              install it normally, please click the button below to install it
              directly
            </div>
            <div
              className={"download"}
              onClick={() => this.handleDownloadApk()}
            >
              download
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}
export default UserAgentConponents;
