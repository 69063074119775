import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore from "swiper";
import { Autoplay } from "swiper";
import "./static/borrowProcess.less";
import process1 from "../assets/imgs/home/process_1.png";
import process2 from "../assets/imgs/home/process_2.png";
import process3 from "../assets/imgs/home/process_3.png";
import process4 from "../assets/imgs/home/process_4.png";
import process5 from "../assets/imgs/home/process_5.png";
import comment1 from "../assets/imgs/home/comment/Angelo_Maniaol.png";
import comment2 from "../assets/imgs/home/comment/Honne.png";
import comment3 from "../assets/imgs/home/comment/Noorlina_Alal.png";
import comment4 from "../assets/imgs/home/comment/Tine_Gasta.png";
import comment5 from "../assets/imgs/home/comment/Vancis.png";

export const BorrowProcess = ({ isMobile }) => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    {
      no: "1",
      desc: "Download the OKPeso APP and register",
      desc1:
        "Our application is temporarily unavailable in the GooglePlay Store. Please use the Android application store or APK for an early experience.",
    },
    { no: "2", desc: "Fill out your information" },
    { no: "3", desc: "Wait for approval" },
    { no: "4", desc: "Submit a loan order and get money" },
    { no: "5", desc: "Repay on time" },
  ];
  SwiperCore.use([Autoplay]);

  const images = [process1, process2, process3, process4, process5];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prevStep) => (prevStep + 1) % steps.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={isMobile? "brrow-process-container-mobile" :"brrow-process-container"}>
      <div className={isMobile? "borrow-process-selections-mobile" :"borrow-process-selections"}>
        {!isMobile? (<div className="process-imgs">
          {/* <img src={a1} alt="process-imgs" /> */}
          <img src={images[activeStep]} alt={`step-${activeStep + 1}`} />
        </div>) : ""}
        <div className="process-desc">
          <h3>How to borrow?</h3>
          <div className="yellow-area"></div>
          <div className="step-selection">
            {steps.map((step, index) => (
              <div
                key={index}
                className={`step ${index === activeStep ? "active" : ""}`}
                onMouseEnter={() => setActiveStep(index)}
              >
                <span className="step-no">{step.no}</span>
                <span className="desc">{step.desc}</span>
                {step.desc1 && <p className="desc1">{step.desc1}</p>}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="comment-area">
        <h3>Don’t just take our word for it</h3>
        <div className="color-area"></div>
        <p>
          Hear it straight from the people who trust us-our satisfied and happy
          clients!
        </p>
        <div className="comment">
          <Swiper
            spaceBetween={50}
            slidesPerView={0.7}
            centeredSlides
            className="swiper"
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            loop={true}
            onSlideChange={() =>""}
            onSwiper={(swiper) => console.log(swiper)}
          >
            <SwiperSlide>
              <img src={comment1} alt="comment1" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={comment2} alt="comment2" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={comment3} alt="comment3" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={comment4} alt="comment4" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={comment5} alt="comment5" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

// import React from "react";
// import "./static/borrowProcess.less";
// import a1 from "../assets/imgs/avatar/Arnold.jpg";

// export const BorrowProcess = () => {
//   return (
//     <div className="borrow-process-container">
//       <div className="process-imgs">
//         <img src={a1} alt="process-imgs" />
//       </div>
//       <div className="process">
//         <h3>How to borrow?</h3>
//         <div className="yellow-area"></div>
//         <div className="step-selection">
//           <div className="step">
//             <span className="step-no">1</span>
//             <span className="desc">Download the OKPeso APP and register</span>
//             <span className="desc1">
//               Our application is temporarily unavailable in the Google Play
//               Store.Please use the Android application store or APK for an early
//               experience .
//             </span>
//           </div>
//           <div className="step">
//             <span className="step-no">2</span>
//             <span className="desc">Fill out your information</span>
//           </div>
//           <div className="step">
//             <span className="step-no">3</span>
//             <span className="desc">Wait for approval</span>
//           </div>
//           <div className="step">
//             <span className="step-no">4</span>
//             <span className="desc">Submit a loan order and get money</span>
//           </div>
//           <div className="step">
//             <span className="step-no">5</span>
//             <span className="desc">Repay on time</span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
