import React from 'react';
import './static/features.less';
import p1 from "../assets/imgs/home/product/1@2x.png"
import p2 from "../assets/imgs/home/product/2@2x.png"
import p3 from "../assets/imgs/home/product/3@2x.png"
import p4 from "../assets/imgs/home/product/4@2x.png"

export const Features = ({isMobile}) => {
  const featuresData = [
    {
      icon: p1,
      title: "10s to register",
      description: "You only need 10 seconds to register your account."
    },
    {
      icon: p2, 
      title: "1 min to apply",
      description: "The application process can be done in 1 minute."
    },
    {
      icon: p3, 
      title: "5 mins to get loan",
      description: "Take 5 minutes to calculate your loan amount."
    },
    {
      icon: p4, 
      title: "Amount up to 30,000",
      description: "You can get up to 30,000 real-time application."
    }
  ];

  return (
    <div className={isMobile ? "features-container-mobile" :"features-container" }>
      <h2 className="features-title">Features of our products</h2>
      <div className="features color-area"/>
      <div className="features-grid">
        {featuresData.map((feature, index) => (
          <div className="feature-card" key={index}>
            <img className="feature-icon" src ={feature.icon} alt="feature-icon"/>
            {/* <div className="feature-icon">{feature.icon}</div> */}
            <div className="feature-selection">
              <h3 className="feature-title">{feature.title}</h3>
              <p className="feature-description">{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};