import React,{useEffect,useState} from "react";

const  AnimatedNumber = ({ start, end, duration, className }) => {
    const [number, setNumber] = useState(start);
  
    useEffect(() => {
      const startTime = performance.now();
  
      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1); // 计算进度
        const currentNumber = start + (end - start) * progress; // 动态计算当前值
  
        setNumber(currentNumber);
  
        if (progress < 1) {
          requestAnimationFrame(animate);
        }
      };
  
      requestAnimationFrame(animate);
    }, [start, end, duration]);
  
    return (
      <span className={className}>
        {Number.isInteger(start) && Number.isInteger(end)
          ? Math.round(number).toLocaleString()
          : number.toFixed(1)}
      </span>
    );
  };
  
  export default AnimatedNumber;
