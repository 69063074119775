import ANUDON from "@/assets/avatar/ANUDON.png";
import AUSTRIA from "@/assets/avatar/AUSTRIA.png";
import BACAO from "@/assets/avatar/BACAO.png";
import ENRIQUEZ from "@/assets/avatar/ENRIQUEZ.png";
import GAMBOA from "@/assets/avatar/GAMBOA.png";
import GERONA from "@/assets/avatar/GERONA.png";
import GODITO from "@/assets/avatar/GODITO.png";
import JOHNPHILIP from "@/assets/avatar/JOHNPHILIP.png";
import MOVIDA from "@/assets/avatar/MOVIDA.png";
import NAVARRO from "@/assets/avatar/NAVARRO.png";
import QUEZON from "@/assets/avatar/QUEZON.png";
import ROJAS from "@/assets/avatar/ROJAS.png";
import SORIANO from "@/assets/avatar/SORIANO.png";
import VILLANUEVA from "@/assets/avatar/VILLANUEVA.png";

export const Avatars = {
  ANUDON,
  AUSTRIA,
  BACAO,
  ENRIQUEZ,
  GAMBOA,
  GERONA,
  GODITO,
  JOHNPHILIP,
  MOVIDA,
  NAVARRO,
  QUEZON,
  ROJAS,
  SORIANO,
  VILLANUEVA,
};
