import React from "react";
import PropTypes from "prop-types";
import { Menu, Button, Popover } from "antd";

import { enquireScreen } from "enquire-js";
// import websiteLogo from "../assets/imgs/website-logo.png";
import websiteLogo from "../assets/imgs/home/组 4200@2x.png";
import "./static/header.less";
import downloadImg from "../assets/imgs/home/下载@2x.png"

// import { downloadClick } from "../utils/common";

class Header extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    activeFAQ: PropTypes.bool,
    activePrivacy: PropTypes.bool,
    activeProduct: PropTypes.bool,
    activeHome: PropTypes.bool,
    handleclick: PropTypes.func,
  };

  state = {
    menuVisible: false,
    menuMode: "horizontal",
    downloadUrl: "",
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ menuMode: b ? "inline" : "horizontal" });
    });
  }

  handleShowMenu = () => {
    // console.log("handleShowMenu", this.state.menuVisible);
    this.setState({ menuVisible: !this.state.menuVisible });
  };

  handleMenuClick = (event) => {
    this.props.handleclick(event.key);
    this.setState({ menuVisible: false });
  };

  render() {
    const { activeFAQ, activeHome, handleclick, isMobile, activePrivacy } =
      this.props;
    const { menuMode, menuVisible } = this.state;

    const menu = (
      <Menu
        mode="inline"
        id="nav"
        key="nav"
        onClick={(e) => this.handleMenuClick(e)}
      >
        <Menu.Item key="Home">
          <span>Borrow</span>
        </Menu.Item>
        <Menu.Item key="FAQ">
          <span>FAQ</span>
        </Menu.Item>
        <Menu.Item key="PrivacyPolicy">
          <span>Privacy Policy</span>
        </Menu.Item>
        {/* <Menu.Item key="blogs">
          <span>Blogs</span>
        </Menu.Item> */}
        <Menu.Item key="Download">
          <img src={downloadImg} alt="Downloadimg"></img>
          <span>Download the OFFICIAL OKPeso APK</span>
        </Menu.Item>
      </Menu>
    );
    // console.log(this.props);
    return (
      <header {...this.props}>
        <div className="headerWrapper">
          <span className="logobox">
            <img
              src={websiteLogo}
              className={isMobile ? "logoMobile" : "logo"}
              alt="logo"
            />
            <span className={isMobile ? "logoTipMobile" : "logoTip"}>
              ( OKApp, OKPS ){" "}
            </span>
          </span>
          {menuMode === "horizontal" ? (
            <div>
              <div
                className={"header-download-btn"}
                onClick={() => handleclick("Download")}
              >
                <Button>Download</Button>
              </div>
              {/* <div
                className={activeHome ? "active-btn" : "button"}
                onClick={() => handleclick("Blog")}
              >
                <Button>Blog</Button>
              </div> */}
              <div
                className={activePrivacy ? "active-btn" : "button"}
                onClick={() => handleclick("PrivacyPolicy")}
              >
                <Button>Privacy Policy</Button>
              </div>
              <div
                className={activeFAQ ? "active-btn" : "button"}
                onClick={() => handleclick("FAQ")}
              >
                <Button>FAQ</Button>
              </div>
              {/* <div className={activeProduct ? 'active-btn' : 'button'} onClick={() => handleclick('product')}>
              <Button>Product</Button>
            </div> */}
              <div
                className={activeHome ? "active-btn" : "button"}
                onClick={() => handleclick("Home")}
              >
                <Button>Borrow</Button>
              </div>
            </div>
          ) : (
            <Popover
              overlayClassName="popover-menu"
              placement="bottomRight"
              content={menu}
              trigger="click"
              visible={menuVisible}
              arrowPointAtCenter
              onVisibleChange={this.handleShowMenu}
            >
              <svg className="nav-phone-menu" onClick={this.handleShowMenu}>
                <use xlinkHref="#iconmenu1" />
              </svg>
            </Popover>
          )}
        </div>
      </header>
    );
  }
}

export default Header;
