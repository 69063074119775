import "./static/dataArea.less";
import React from "react";
import phoneIcon from "../assets/imgs/home/路径 8460@2x.png";
import bookIcon from "../assets/imgs/home/组 4458@2x.png";
import yesIcon from "../assets/imgs/home/路径 12064@2x.png";
import starIcon from "../assets/imgs/home/路径 12063@2x.png";
import AnimatedNumber from "../components/AnimatedNumber"

export const DataArea = ({ isMobile }) => {
  return (
    <div className={isMobile ? "dataArea-home-mobile" : "dataArea-home"}>
      <div className="selection">
        <img className="icon" src={phoneIcon} alt="phoneIcon" />
        <div className="textContainer">
          <AnimatedNumber
            className="number"
            start={0}
            end={58626225}
            duration={2000}
          />
          {/* <p className="number">58,626,225</p> */}
          <p className="desc">Total App Downloads</p>
        </div>
      </div>
      <div className="selection">
        <img className="icon" src={bookIcon} alt="bookIcon" />
        <div className="textContainer">
          <AnimatedNumber
            className="number"
            start={0}
            end={11469868}
            duration={2000}
          />
          {/* <p className="number">11,469,868</p> */}
          <p className="desc">Total App Registrations</p>
        </div>
      </div>
      <div className="selection">
        <img className="icon" src={yesIcon} alt="yesIcon" />
        <div className="textContainer">
          <AnimatedNumber
            className="number"
            start={0}
            end={10744041}
            duration={2000}
          />
          {/* <p className="number">10,744,041</p> */}
          <p className="desc">Approved Transactions</p>
        </div>
      </div>
      <div className="selection">
        <img className="icon" src={starIcon} alt="starIcon" />
        <div className="textContainer">
          <AnimatedNumber
            className="number"
            start={0.0}
            end={4.9}
            duration={2000}
          />
          <p className="desc">Total Ratings</p>
        </div>
      </div>
    </div>
  );
};

// const AnimatedNumber = ({ start, end, duration,className }) => {
// const [number, setNumber] = useState(start);

// useEffect(() => {
//   const startTime = performance.now();

//   const animate = (currentTime) => {
//     const elapsedTime = currentTime - startTime;
//     const progress = Math.min(elapsedTime / duration, 1); // 计算进度
//     const currentNumber = Math.floor(start + (end - start) * progress);

//     setNumber(currentNumber);

//     if (progress < 1) {
//       requestAnimationFrame(animate);
//     }
//   };

//   requestAnimationFrame(animate);
// }, [start, end, duration]);

// return <span className={className}>{Number.isInteger(number) ? number.toLocaleString() : number}</span>;
// };

