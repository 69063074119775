import React, { useState } from "react";
import "./index.less";
import inputSelect from "@/assets/landing/inputSelect.png";

const errors = {
  empty: "Please fill the blanks",
  limit: "Letters",
  emailLimit: "Email",
  phoneLimit: "Please input 10-digit Philippine number starting with 9 or 8",
};
const Input = (prop) => {
  const {
    isMobile = true,
    obj = { name: "" },
    type = "text",
    check = false,
    //inputProp : maxLength minLength
    inputProp = {},
  } = prop;
  const [errMsg, setErrMsg] = useState(
    obj.name.indexOf("Opt") === -1 ? errors.empty : ""
  );
  const [sValue, setSValue] = useState(null);
  const [hasError, setHasError] = useState(false);
  const onChange = (e, key) => {
    console.log("addrDetail", obj);
    const {
      target: { value },
    } = e;
    if (value) {
      const textRule = /^[Ña-zA-Z]+([a-zA-ZⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩⅪⅫÑ\s\.'"-]*)$/;
      const emailRule =
        /^[a-zA-Z0-9][\w-]*((\.[\w-]+)*)@\w+(\.\w{2,3}){0,1}\.([A-Za-z]{2,4})$/;
      const phoneRule = /^((9[0-9])|(09[0-9])|(8[0-9])|(08[0-9]))\d{8}$/;
      let check = true;
      if (type === "text") {
        check = !!textRule.test(value);
        console.log("check");
        if (!check) {
          setHasError(true);
          setErrMsg(errors.limit);
        } else {
          setErrMsg(null);
        }
      }
      if (type === "email") {
        check = !!emailRule.test(value);
        console.log("check");
        if (!check) {
          setHasError(true);
          setErrMsg(errors.emailLimit);
        } else {
          setErrMsg(null);
        }
      }
      if (type === "phone") {
        check = !!phoneRule.test(value);
        console.log("check");
        if (!check) {
          setHasError(true);
          setErrMsg(errors.phoneLimit);
        } else {
          setErrMsg(null);
        }
      }
      if (check) {
        setErrMsg(null);
      }
      prop.onChange(obj.name, value, check);
    } else {
      if (obj.name.indexOf("Opt") === -1) {
        setHasError(true);
        setErrMsg(errors.empty);
      }
      prop.onChange(obj.name, value, false);
    }
  };
  const onSelect = (value) => {
    prop.onChange(obj.name, value, true);
    setSValue(value);
    setErrMsg(null);
  };
  console.log("inputProp", inputProp);

  return (
    <div className={["aInput", !isMobile && "aInputPc"].join(" ")}>
      <div className={"aTitle"}>
        <div>{obj.title}</div>
        {type === "select" && (
          <div className={"atSelect"}>
            <div className={"atOption"} onClick={() => onSelect("1")}>
              {sValue === "1" ? (
                <img className="atOptionOn" src={inputSelect} />
              ) : (
                <div className="option" />
              )}{" "}
              Male
            </div>
            <div className={"atOption"} onClick={() => onSelect("2")}>
              {sValue === "2" ? (
                <img className="atOptionOn" src={inputSelect} />
              ) : (
                <div className="option" />
              )}{" "}
              Female
            </div>
          </div>
        )}
      </div>
      {/* <div className={"aValue"}> </div> */}
      {type !== "select" && (
        <input
          className={"aValue"}
          placeholder={obj.desc}
          value={obj && obj.value}
          onChange={onChange}
          // onKeyDown={onChange}
          type={type === "phone" ? "tel" : "text"}
          {...inputProp}
        />
      )}

      {(check || hasError) && errMsg && (
        <div className="aInputError">{errMsg}</div>
      )}
    </div>
  );
};
export default Input;
