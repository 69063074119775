import { generateData } from "@/utils/landing";
import React, { useState } from "react";
import styles from "./index.less";
import msgImg from "@/assets/landing/v1/msg@2x.png";

const InfoMessage = (prop) => {
  // const [msgInfo] = useState(generateData('@phone received ₱@amount one minute!'));

  const msgInfo = [
    "Juan D*** received ₱5400 1 minute ago",
    "Maria S*** received ₱6300 3 minutes ago",
    "Pedro F*** received ₱3500 5 minutes ago",
    "Isabella R*** received ₱4000 half an hour ago",
    "Carlos S*** received ₱5000 8 minutes ago",
    "Maria I*** received ₱2500 6 minutes ago",
    "Joselito M*** received ₱3000 1 minute ago",
    "Rosario d*** received ₱9800 6 minutes ago",
    "Antonio G*** received ₱7600 9 minutes ago",
    "Maria L*** received ₱5200 1 minute ago",
    "Ferdinand M*** received ₱10000 3 minutes ago",
    "Imelda R*** received ₱2000 7 minutes ago",
    "Benigno A*** received ₱12000 5 minutes ago",
    "Corazon C*** received ₱6300 6 minutes ago",
    "Ramon M*** received ₱4500 3 minutes ago",
    "Gloria M*** received ₱4000 1 minute ago",
    "Fidel V*** received ₱8000 5 minutes ago",
    "Joseph E*** received ₱9000 1 hour ago",
    "Leni R*** received ₱8500 3 minute ago",
    "Rodrigo D*** received ₱2800 5 minutes ago",
    "Carlos C*** received ₱6500 1 hour ago",
    "Pedro P*** received ₱5000 9 minutes ago",
    "Juana L*** received ₱6400 3 minutes ago",
    "Maria C*** received ₱2800 5 minutes ago",
    "Jose R*** received ₱7000 1 minute ago",
  ];
  const { isMobile = true } = prop;
  console.log("isMobileisMobile", isMobile);
  return (
    <div className={["infoMessage", !isMobile && "infoMessagePc"].join(" ")}>
      <div className={"itemAll"}>
        {msgInfo.map((item, idx) => (
          <div className={"itemOne"}>
            <img className={"itemImg"} src={msgImg} />
            <span>{item}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoMessage;
